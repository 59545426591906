import todayName from "./todayName";
import dayjs from "dayjs";
import "dayjs/locale/fr";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const getFullDatesWithoutWeekendDays = (dates) => {
  const days = [];
  if (dates.length) {
    dates.forEach((date) => {
      const day = todayName(date.date);
      if (day !== "samedi") {
        if (day !== "dimanche") {
          days.push({ date: new Date(date.date), nombre: date.nombre });
        }
      }
    });
  }

  return days;
};
export default getFullDatesWithoutWeekendDays;
