import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Router, Route } from "react-router-dom";
import InstallPwa from "./installPwa";

import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import { history } from "./history";
import { Toaster } from "react-hot-toast";
import { routes } from './routes/app.routes';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  console.log("Build date : ", getBuildDate(packageJson.buildDate));

  return (
    <>
      <Router history={history}>
        <div className="router-container">
          <InstallPwa />
          {
            routes.map(({path, component}) => (
              <Route key={path} exact path={path} component={component} />
            ))
          }
        </div>
      </Router>
      <Toaster
        position="top-center"
        containerStyle={{
          top: 100,
          // left: 280,
          // bottom: 20,
          // right: 20,
        }}
        reverseOrder={false}
      />
    </>
  );
}

export default App;
