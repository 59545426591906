import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import localforage from 'localforage';
import { combineReducers } from 'redux';

import pointme from './slices/pointmeSlice'
import users  from './slices/userSlice'
import absence from './slices/absenceSlice';
import historiquePointage from './slices/historiqueSlice';
import storage  from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/es/storage/session'


const persistConfig = {
  key: 'root',
  storage: localforage
}

const rootReducer = combineReducers({ 
  users,
  pointme,
  absence,
  historiquePointage
}) 

const persistedReducer = persistReducer(persistConfig, rootReducer)

 

export const store = configureStore({
  reducer:persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)