import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { absenceType, getAbsencesBetweenTwoDates, getPointageBetweenTwoDates, presenceType } from "../../redux/slices/historiqueSlice";
import { getHistoriqueAbsence, getHistoriquePointage } from "../../redux/services/getHistoriquePointage";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const HistoriqueTable = ({userId}) => {
  const historiqueType = useSelector(
    (state) => state.historiquePointage.historiqueType
  );
  const historiquePointage = useSelector(
    (state) => state.historiquePointage?.pointages
  );
  const historiqueAbsence = useSelector(
    (state) => state.historiquePointage?.absences
  );
  const dateInterval = useSelector(
    (state) => state.historiquePointage.dateInterval
  );
  const dispatch = useDispatch();

  const giveGoodDateFormat = (cell, row) => {
    return dayjs(cell).format("DD MMMM YYYY");
  };

  const isJustifiedAbsence = (cell) => {
    return cell ? "Oui" : "Non"
  }

  return (
    <div>
      <div style={{backgroundColor: '#efefef'}} className="mt-3 mb-3 border-top py-2">
        <span
          onClick={() => {
            dispatch(presenceType())
            if(dateInterval.length){
              dispatch(getPointageBetweenTwoDates(dateInterval))
            }
          }}
          className="py-1 px-2 span-pointer"
          style={historiqueType === 'presence' ? {backgroundColor: '#fff'} : null}
        >
          Pointages <span class="badge badge-success fs-1">{historiquePointage.length}</span>
        </span>
        <span onClick={() => {
          dispatch(absenceType())
          if(dateInterval.length){
            dispatch(getAbsencesBetweenTwoDates(dateInterval))
          }
          }} 
          style={historiqueType !== 'presence' ? {backgroundColor: '#fff'} : null}
          className="py-1 px-2 span-pointer">
          Absences <span class="badge badge-danger">{historiqueAbsence.length}</span>
        </span>
      </div>
      <div className="mb-5" style={{ minWidth: "300px", overflowX: "scroll" }}>
        <BootstrapTable
          exportCSV
          noDataIndication={() => "loading..."}
          maxWidth="1000"
          bordered
          options={{}}
          pagination
          data={
            historiqueType === "presence"
              ? historiquePointage
              : historiqueAbsence
          }
          striped={true}
          hover={true}
        >
          <TableHeaderColumn
            dataField={
              historiqueType === "presence" ? `date_pointage` : `date_absence`
            }
            isKey={true}
            dataAlign="center"
            dataSort={true}
            dataFormat={giveGoodDateFormat}
          >
            Date
          </TableHeaderColumn>
          {historiqueType === "presence" && (
            <TableHeaderColumn
              dataField="arriving_time"
              dataSort={true}
              dataAlign="center"
            >
              Heure d'arrivée
            </TableHeaderColumn>
          )}
          {historiqueType === "presence" && (
            <TableHeaderColumn
              dataField="departure_time"
              dataSort={true}
              dataAlign="center"
            >
              Heure départ
            </TableHeaderColumn>
          )}

          {historiqueType !== "presence" && (
            <TableHeaderColumn
              dataField="is_justify"
              dataSort={true}
              dataAlign="center"
              dataFormat={isJustifiedAbsence}
            >
              Justifiée
            </TableHeaderColumn>
          )}
        </BootstrapTable>
      </div>
    </div>
  );
};

export default React.memo(HistoriqueTable);
