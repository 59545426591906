import React, { Component, useEffect, useState } from "react";
import "./gestionAbsenceRetard.css";
import Header from "./../header/header";
import Sidebar from "./../sidebar/sidebar";
import Footer from "./../footer/footer";
import { API } from "./../../appvars/globalsvariables";
import axios from "axios";
import { Chart } from "react-charts";
// import { NavLink } from 'react-router-dom';
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import WeeklyPointageChart from "../weeklyPointageChart/index";
import { getAllAbsenceAsync } from "../../redux/slices/absenceSlice";
import { useDispatch, useSelector } from "react-redux";
import AppStructure from "../AppStructure";

const series = () => ({
  type: "bar",
});

const axes = [
  { primary: true, type: "ordinal", position: "bottom" },
  { position: "left", type: "linear", stacked: false },
];

const GestionAbsenceRetar = () => {

  const api = API

  const [countWeeklyPointages, setCountWeeklyPointages] = useState([])
  const [dailyAbsences, setDailyAbsences] = useState([])
  const [cwlpdata, setCwlpdata] = useState([])
  const [cwlpday, setCwlpday] = useState([])
  const [countDailyAbsence, setCountDailyAbsence] = useState(0)
  const [dailyRetardAprem, setDailyRetardAprem] = useState([])
  const [countDailyRetardAprem, setCountDailyRetardAprem] = useState([])
  const [dailyRetardMatin, setDailyRetardMatin] = useState([])
  const [countDailyRetardMatin, setCountDailyRetardMatin] = useState(0)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isEmptyData, setIsEmptyData] = useState(false)
  const [isErrorRequest, setIsErrorRequest] = useState(false)
  const [dailyAbsenceMatin, setDailyAbsenceMatin] = useState([])
  const [countDailyAbsenceMatin, setCountDailyAbsenceMatin] = useState(0)
  const [dailyAbsenceAprem, setDailyAbsenceAprem] = useState([])
  const [countDailyAbsenceAprem, setCountDailyAbsenceAprem] = useState(0)
  const [yesterdayAbsences, setYesterdayAbsences] = useState([])
  const [countYesterdayAbsence, setCountYesterdayAbsence] = useState(0)
  const dispatch = useDispatch()

  const DailyAbsenceMatin = useSelector(state => state.absence.absence.dataAbsenceMatin)
  const YesterdayAbsences = useSelector(state => state.absence.absence.dataYesterdayAbsence)
  const DailyRetardAprem = useSelector(state => state.absence.absence.dataRetardAm)
  const DailyAbsences = useSelector(state => state.absence.absence.dataAbsence)
  const DailyRetardMatin = useSelector(state => state.absence.absence.dataRetardMatin)
  const CountDailyRetardMatin = useSelector(state => state.absence.absence.countRetardMatin)


  useEffect(() => {
    onGetListeAbsenceRetard()
    dispatch(getAllAbsenceAsync())
  }, [])

  const onGetListeAbsenceRetard = () => {

    axios
      .get(api + "daily-absence-list")
      .then((response) => {
        if (response.data.success) {
          setDailyAbsences(response.data.dataAbsence)
          setCountDailyAbsence(response.data.countDailyAbsence)
          setDailyAbsenceMatin(response.data.dataAbsenceMatin)

          setCountDailyAbsenceMatin(response.data.countAbsenceMatin)
          setDailyAbsenceAprem(response.data.dataAbsenceAm)
          setCountDailyAbsenceAprem(response.data.countAbsenceAm)
          setDailyRetardMatin(response.data.dataRetardMatin)

          setCountDailyRetardMatin(response.data.countRetardMatin)
          setDailyRetardAprem(response.data.dataRetardAm)


          setCountDailyRetardAprem(response.data.countRetardAm)
          setYesterdayAbsences(response.data.dataYesterdayAbsence)

          setCountYesterdayAbsence(response.data.countYesterdayAbsence)
        } else {
          setIsEmptyData(true)
          console.log("else")
        }
      })
      .catch((error) => {
        console.log(error.message);
        setIsErrorRequest(true)
      });
  };

  const handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  const createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  const createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  };


  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    btnGroup: createCustomButtonGroup,
    noDataText: "Aucune donnée",
  };

  if (isPageLoading) {
    return (
      <div className="component-gestion-absence-retard">
        {/* Header render */}
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar render */}
          <Sidebar />
          <div className="page-holder w-100 d-flex flex-wrap">
            <div className="container-fluid px-xl-5">
              {/* Page content */}
              <section className="py-5">
                <div className="row dashboard-row">
                  <div className="col-lg-6 mb-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="h6 text-uppercase mb-0">
                          Liste des absences
                        </h3>
                      </div>
                      <div className="card-body">In progress</div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="h6 text-uppercase mb-0">
                          Liste des retards
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="col-md-12 delay-table-col">
                          {/* TODO */}
                          Liste des retards
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Footer render */}
            <Footer />
          </div>
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Chargement en cours</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AppStructure>
      <div className="container-fluid px-xl-5">
        {/* Page content */}
        <section className="py-5">
          <div className="row dashboard-row">
            <div className="col-lg-6 mb-5">
              <div className="card">
                <div className="card-header bk_green_bg">
                  <h3 className="h6 text-uppercase mb-0">
                    Liste des absences
                  </h3>
                </div>
                <div className="card-body">
                  <ul
                    className="nav nav-tabs"
                    id="yat_absence_tabs"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="ta_matin"
                        data-toggle="tab"
                        href="#today_absence_matin_link"
                        role="tab"
                        aria-controls="today_absence_matin_link"
                        aria-selected="true"
                      >
                        Matin{" "}
                        <span className="badge badge-danger pm_success_badge">
                          {" "}
                          {countDailyAbsenceMatin}{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="ta_aprem"
                        data-toggle="tab"
                        href="#today_absence_aprem_link"
                        role="tab"
                        aria-controls="today_absence_aprem_link"
                        aria-selected="true"
                      >
                        Aprés-midi{" "}
                        <span className="badge badge-danger pm_success_badge">
                          {" "}
                          {countDailyAbsenceAprem}{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="today_absence"
                        data-toggle="tab"
                        href="#today_absence_link"
                        role="tab"
                        aria-controls="today_absence_link"
                        aria-selected="true"
                      >
                        Aujourd'hui
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="yesterday_absence"
                        data-toggle="tab"
                        href="#yesterday_absence_link"
                        role="tab"
                        aria-controls="yesterday_absence_link"
                        aria-selected="false"
                      >
                        Hier{" "}
                        <span className="badge badge-danger pm_success_badge">
                          {" "}
                          {countYesterdayAbsence}{" "}
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="absenceTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="today_absence_matin_link"
                      role="tabpanel"
                      aria-labelledby="today_absence_matin_link-tab"
                    >
                      {/* todo */}
                      <div className="col-md-12 ta-table-col">
                        <BootstrapTable
                          data={DailyAbsenceMatin}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          options={options}
                          exportCSV
                          pagination
                        >
                          <TableHeaderColumn
                            dataAlign="center"
                            width="50"
                            dataField="id"
                            isKey={true}
                            hidden={true}
                          >
                            {" "}
                            ID
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataAlign="center"
                            width="150"
                            dataField="todayFrenchDateFormat"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Date du jour
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign="center"
                            width="200"
                            dataField="bakelisteFullname"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Prénom et Nom
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                        </BootstrapTable>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="today_absence_aprem_link"
                      role="tabpanel"
                      aria-labelledby="today_absence_aprem_link-tab"
                    >
                      {/* todo */}
                      <div className="col-md-12 ta-table-col">
                        <BootstrapTable
                          data={DailyRetardAprem}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          options={options}
                          exportCSV
                          pagination
                        >
                          <TableHeaderColumn
                            dataAlign="center"
                            width="50"
                            dataField="id"
                            isKey={true}
                            hidden={true}
                          >
                            {" "}
                            ID
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataAlign="center"
                            width="150"
                            dataField="todayFrenchDateFormat"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Date du jour
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign="center"
                            width="200"
                            dataField="bakelisteFullname"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Prénom et Nom
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                        </BootstrapTable>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="today_absence_link"
                      role="tabpanel"
                      aria-labelledby="today_absence_link-tab"
                    >
                      {/* todo */}
                      <div className="col-md-12 ta-table-col">
                        <BootstrapTable
                          data={DailyAbsences}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          options={options}
                          exportCSV
                          pagination
                        >
                          <TableHeaderColumn
                            dataAlign="center"
                            width="50"
                            dataField="id"
                            isKey={true}
                            hidden={true}
                          >
                            {" "}
                            ID
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataAlign="center"
                            width="150"
                            dataField="todayFrenchDateFormat"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Date du jour
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign="center"
                            width="200"
                            dataField="bakelisteFullname"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Prénom et Nom
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                        </BootstrapTable>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="yesterday_absence_link"
                      role="tabpanel"
                      aria-labelledby="yesterday_absence_link-tab"
                    >
                      <div className="col-md-12 ta-table-col">
                        <BootstrapTable
                          data={YesterdayAbsences}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          options={options}
                          exportCSV
                          pagination
                        >
                          <TableHeaderColumn
                            dataAlign="center"
                            width="50"
                            dataField="id"
                            isKey={true}
                            hidden={true}
                          >
                            {" "}
                            ID
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataAlign="center"
                            width="150"
                            dataField="yesterdayFrenchDateFormat"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Date du jour
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign="center"
                            width="200"
                            dataField="bakelisteFullname"
                            filter={{ type: "TextFilter", delay: 1000 }}
                          >
                            Prénom et Nom
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>

                  {/* <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                              <li className="active"><a href="#today_absence" data-toggle="tab" aria-expanded="true">Aujourd'hui</a></li>
                              <li className=""><a href="#yesterday_absence" data-toggle="tab" aria-expanded="false">Hier</a></li>
                            </ul>
                          </div>

                          <div className="tab-content">
                            <div className="tab-pane active" id="today_absence">
                              <div className="row ta-table-row">
                              
                              </div>
                            </div>

                            <div className="tab-pane" id="yesterday_absence">
                              <div className="row ta-table-row">
                                
                              </div>
                            </div>

                          </div>
                        </div> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-5">
              <div className="card">
                <div className="card-header bk_orange_bg">
                  <h3 className="h6 text-uppercase mb-0">
                    Liste des retards du matin
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable
                      data={DailyRetardMatin}
                      striped={true}
                      hover={true}
                      condensed={true}
                      multiColumnSort={2}
                      options={options}
                      exportCSV
                      pagination
                    >
                      <TableHeaderColumn
                        dataAlign="center"
                        width="50"
                        dataField="id"
                        isKey={true}
                        hidden={true}
                      >
                        {" "}
                        ID
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                      {/* <TableHeaderColumn dataAlign="center"
                              width='100'
                              dataField='id'
                              dataFormat={this.actionsFormatter.bind(this)} >
                                Actions</TableHeaderColumn> */}
                      <TableHeaderColumn
                        dataAlign="center"
                        width="150"
                        dataField="todayFrenchDateFormat"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Date du jour
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign="center"
                        width="200"
                        dataField="bakelisteFullname"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Prénom et Nom
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn dataAlign="center"
                              width='200'
                              dataField='user_status'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Statuts</TableHeaderColumn> */}
                      <TableHeaderColumn
                        dataAlign="center"
                        width="150"
                        dataField="arriving_time"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Heure d'arrivée
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              {/* End matin */}
              <hr />
              {/* Début aprém */}
              <div className="card">
                <div className="card-header bk_red_bg">
                  <h3 className="h6 text-uppercase mb-0">
                    Liste des retards de l'aprés-midi
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable
                      data={DailyRetardAprem}
                      striped={true}
                      hover={true}
                      condensed={true}
                      multiColumnSort={2}
                      options={options}
                      exportCSV
                      pagination
                    >
                      <TableHeaderColumn
                        dataAlign="center"
                        width="50"
                        dataField="id"
                        isKey={true}
                        hidden={true}
                      >
                        {" "}
                        ID
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                      {/* <TableHeaderColumn dataAlign="center"
                              width='100'
                              dataField='id'
                              dataFormat={this.actionsFormatter.bind(this)} >
                                Actions</TableHeaderColumn> */}
                      <TableHeaderColumn
                        dataAlign="center"
                        width="150"
                        dataField="todayFrenchDateFormat"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Date du jour
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign="center"
                        width="200"
                        dataField="bakelisteFullname"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Prénom et Nom
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn dataAlign="center"
                              width='200'
                              dataField='user_status'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Statuts</TableHeaderColumn> */}
                      <TableHeaderColumn
                        dataAlign="center"
                        width="150"
                        dataField="arriving_time"
                        filter={{ type: "TextFilter", delay: 1000 }}
                      >
                        Heure d'arrivée
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              {/* End Aprem */}
            </div>
          </div>
        </section>
      </div>
    </AppStructure>
  );
}

export default GestionAbsenceRetar;