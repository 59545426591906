import React, { Component, useEffect, useState } from "react";
import "./connexion.css";
import API from "./../../appvars/globalsvariables";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import Logo from "../../assets/images/logo_bak.png";
import { useForm } from 'react-hook-form';
import { logoutAsync, setCurrentUser, setCurrentUserAsync } from "../../redux/slices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import { allPointMeAsync } from "../../redux/slices/pointmeSlice";
import { getBakeliteAbsencePerMonthAsync } from "../../redux/slices/absenceSlice";



const Connexion = ()=>{
    const dispatch = useDispatch()
    let token = useSelector(state => state.users.currentUser.auth_token)
    // const [token, setToken] = useState(tokenValue)
    const user = useSelector(state => state.users.currentUser)
    const api = API.API
    const history = useHistory();
    const {register , handleSubmit,formState: { errors },setError} = useForm()

    const setErr = ()=>{
        setError("password",{message:"email ou mot de passe incorrect"})
    }
    const onLogin = (data)=> dispatch(setCurrentUserAsync(data,setErr))

    const ReduxDataIsValid = async ()=>{
        if(token){
            const res = await axios.get(api + `user/${user.id}` )
            console.log(res.data);
            if(!res.data.data.is_archived) {
                // setToken(null)
                token = null
                dispatch(logoutAsync())
            }else{
                dispatch(allPointMeAsync())
                dispatch(getBakeliteAbsencePerMonthAsync())
            }
        }
    }

    useEffect(()=>{
        ReduxDataIsValid()
    },[])

    return(
        <div className="component-connexion">
            {
                token ?
                <Redirect to="/dashboard" /> 
                :
                <div className="container-fluid">
                <div className="row">
                    <div className="d-none d-md-block col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
                        <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center"></div>
                    </div>
                    <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
                        <div className="ui form mt-5 mb-5">
                            <form onSubmit={handleSubmit(onLogin)} id="login-form">
                                <h2 className="sr-only">Login Form</h2>
                                <div className="illustration">
                                    {/* <i className="icon ion-ios-navigate"></i> */}
                                    <span className="point-label">POINT</span>
                                    <span className="me-label">me</span>
                                </div>
                                <div className="form-group my-3">
                                    <label htmlFor="exampleInputEmail1">Adresse email</label>
                                    <input type="email" className="form-control my-1"  
                                        {...register('email', { required: 'Ce champ est obligatoire! *' })}
                                        placeholder="Entrer votre adresse email"
                                    />
                                </div>
                                {errors.email && <p className="errorMsg">{errors.email.message} </p>}
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Mot de passe</label>
                                    <input type="password" className="form-control my-1"  
                                        {...register('password', { required: 'Ce champ est obligatoire! *' })}
                                        placeholder="Entrer votre mot de passe"
                                    />
                                </div>
                                {errors.password && <p className="errorMsg">{errors.password.message} </p>}
                                <button type="submit" className="btn btn-primary btn-block" >Log in <i className="icon ion-log-in"></i></button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Connexion;