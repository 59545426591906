import React from 'react';
import './footer.css';

const Footer =()=>{

    const year = new Date().getFullYear();
    return (
        <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 text-center text-md-left text-primary">
                <p className="mb-2 mb-md-0">&copy; {year} POINTme by <a href="https://bakeli.tech" target="_blank" rel="noopener noreferrer">Bakeli</a></p>
              </div>
            </div>
          </div>
        </footer>
    );
}

export default Footer;
