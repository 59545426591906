import toast from "react-hot-toast";

function successMsg(message){
  toast.success(message, {
    duration: 1000,
    style: {
      backgroundColor: '#002E25',
      border: '5px solid #009688',
      padding: '10px',
      color: '#fff',
      fontSize: '1rem',
      width: '300px'
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#009688',
    },
  });
}

export default successMsg