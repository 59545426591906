import toast from "react-hot-toast";

function errorMsg(message){
  toast.error(message, {
    duration: 10000,
    style: {
      // backgroundColor: '#f03b2e',
      border: '5px solid #f03b2e',
      padding: '10px',
      // color: '#fff',
      fontSize: '1rem',
      minWidth: '300px'
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#f03b2e',
      fontSize: '2rem'
    },
  });
}

export default errorMsg