import { DASHBOARD_PATH, BAKELISTES_PRESENCE_PATH, ADMIN_PRESENCE_PATH, COACH_PRESENCE_PATH, GESTION_ABSENCE_RETARD_PATH, COUNT_ABSENCE_DASHBOARD_PATH, STATISTIQUES_PATH } from './paths';

export const sidebarRoutes = [
  {
    path: DASHBOARD_PATH,
    label: 'Home',
    iconClass: "o-home-1 mr-3 text-gray"
  },
  {
    path: BAKELISTES_PRESENCE_PATH,
    label: 'Bakelistes',
    iconClass: "o-user-1 mr-3 text-gray"
  },
  {
    path: ADMIN_PRESENCE_PATH,
    label: 'Admin',
    iconClass: "o-user-1 mr-3 text-gray"
  },
  {
    path: COACH_PRESENCE_PATH,
    label: 'Coach',
    iconClass: "o-user-1 mr-3 text-gray"
  },
  {
    path: GESTION_ABSENCE_RETARD_PATH,
    label: 'Gestion',
    iconClass: "o-survey-1 mr-3 text-gray"
  },
  {
    path: COUNT_ABSENCE_DASHBOARD_PATH,
    label: 'Compteur',
    iconClass: "o-survey-1 mr-3 text-gray"
  },
  {
    path: STATISTIQUES_PATH,
    label: 'Statistiques',
    iconClass: "o-survey-1 mr-3 text-gray"
  },
]