import React from "react";
import "./sidebar.css";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes/app.routes";
import { sidebarRoutes } from "../../routes/sidebar.route";
import { useDispatch } from 'react-redux';
import { setShowBakelisteDetailsToFalse } from "../../redux/slices/userSlice";

const Sidebar = () => {
  const dispatch = useDispatch()
  return (
    <div className="component-sidebar">
      <div id="sidebar" className="sidebar py-3 shrink show">
        <div className="text-gray-400 text-uppercase px-3 px-lg-4 py-4 font-weight-bold small headings-font-family">
          MAIN
        </div>
        <ul className="sidebar-menu list-unstyled">
          {sidebarRoutes.map((route) => (
            <NavLink
              key={route.path}
              to={route.path}
              className="sidebar-link text-muted"
              activeClassName="active"
              onClick={() => dispatch(setShowBakelisteDetailsToFalse())}
            >
              <i className={route.iconClass}></i>
              <span>{route.label}</span>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
