import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfWeek,
  endOfWeek,
  isSameDay,
 } from "date-fns";
import { useState, useEffect } from "react";
import * as locales from "react-date-range/dist/locale";
import { DateRangePicker } from "react-date-range";
import './historiquePointage.css'
import { getAbsencesBetweenTwoDates, getPointageBetweenTwoDates, setDateInterval } from "../../redux/slices/historiqueSlice";

const CustomStaticRangeLabelContent = ({ text }) => {
  return (
    <span>
      <i>{text}</i>
      <span className={"random-date-string"}>
        <p>test</p>
      </span>
    </span>
  );
};

const renderStaticRangeLabel = () => (
  <CustomStaticRangeLabelContent text={"This is a custom label content: "} />
);

const HistoriqueSelect = () => {
  const historiqueType = useSelector(
    (state) => state.historiquePointage.historiqueType
  );

  const dispatch = useDispatch()

  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const handleHistorique = (dateInterval) => {
    dispatch(setDateInterval(dateInterval))
    setState(dateInterval);
    if (dateInterval[0].endDate !== dateInterval[0].startDate) {
      if(historiqueType === 'presence'){
        dispatch(getPointageBetweenTwoDates(dateInterval))
      }
      else{
        dispatch(getAbsencesBetweenTwoDates(dateInterval))
      }
      
    }
  };

  const showCalendar = () => {
    const calendar = document.querySelector('.rdrCalendarWrapper')
    calendar.style.display = 'block !important'
    calendar.style.backgroundColor = 'black'
    calendar.style.backgroundColor = "yellow !important"
    console.log('calendar: ', calendar);
  }

  return (
    <>
      <DateRangePicker
        onChange={(item) => handleHistorique([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal"
        locale={locales["fr"]}
        // renderStaticRangeLabel={renderStaticRangeLabel}
        staticRanges={[
          // defaultStaticRanges[0],
          {
            label: "Semaine",
            range: () => ({
              startDate: startOfWeek(new Date()),
              endDate: endOfWeek(new Date())
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
          {
            label: "Mois",
            range: () => ({
              startDate: startOfMonth(new Date()),
              endDate: endOfMonth(new Date())
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
          {
            label: "Année",
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: endOfYear(new Date())
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
        ]}
      />
    </>
  );
};

export default HistoriqueSelect;
