import React, { Component, useEffect, useState } from "react";
import "./dashboard.css";
import Header from "./../header/header";
import Sidebar from "./../sidebar/sidebar";
import Footer from "./../footer/footer";
import { API } from "./../../appvars/globalsvariables";
import axios from "axios";
// import { NavLink } from 'react-router-dom';
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import $ from "jquery";
import MultiSelect from "@khanacademy/react-multi-select";
import { store } from "../../redux/store";
import { allPointMeAsync, submitPointMeArriving, submitPointMeArrivingAsync, submitPointMeDepartureAsync } from "../../redux/slices/pointmeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getBakeliteAbsencePerMonth, getBakeliteAbsencePerMonthAsync } from "../../redux/slices/absenceSlice";
import AppStructure from "../AppStructure";
import { getNombreAVenirThunk, getPointageByDateThunk } from "../../redux/services/getPointageByDateThunk";
import formatDate from "../presences/formatDate";
import successMsg from "../../utils/successMsg";
import errorMsg from '../../utils/errorMsg';




const Dashboard = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.users.currentUser.auth_token)
  const access = useSelector(state => state.users.currentUser.can_access_pointme)
  const api = API
  const [pointages, setPointages] = useState([])
  const [fields, setFields] = useState([])
  const [phone, setPhone] = useState()
  const [countPointages, setCountPointages] = useState(0)
  const [yesterdayPointagesCount, setYesterdayPointagesCount] = useState(0)
  const [weeklyPointagesCount, setWeeklyPointagesCount] = useState(0)
  const [monthlyPointagesCount, setMonthlyPointagesCount] = useState(0)
  const [todayPointages, setTodayPointages] = useState([])
  const [todayPointagesCount, setTodayPointagesCount] = useState([])
  const [yesterdayPointages, setYesterdayPointages] = useState([])
  const [weeklyPointages, setWeeklyPointages] = useState([])
  const [monthlyPointages, setMonthlyPointages] = useState([])
  const [forSelect, setForSelect] = useState([])
  const [pointMeInProgress, setPointMeInProgress] = useState(false)
  const [isSuccessPointAlert, setIsSuccessPointAlert] = useState(false)
  const [isErrorPointAlert, setIsErrorPointAlert] = useState(false)
  const [todayPointageLoading, setTodayPointageLoading] = useState(false)
  const [departurePointInProgress, setDeparturePointInProgress] = useState(false)
  const [isSuccessPointDepartureAlert, setIsSuccessPointDepartureAlert] = useState(false)
  const [isErrorPointDepartureAlert, setIsErrorPointDepartureAlert] = useState(false)
  const [empty, setEmpty] = useState(false)
  const [groupyPointageInProgress, setGroupyPointageInProgress] = useState(false)
  const [isGroupy, setIsGroupy] = useState(false)
  const [groupyPointageError, setGroupyPointageError] = useState(false)
  const [selected, setSelected] = useState([])
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm()


  const dataDaily = useSelector(state => state.pointme.pointme.dataDaily)

  const refreshData = ()=>{
    dispatch(allPointMeAsync())
    dispatch(getBakeliteAbsencePerMonthAsync())
  }

  useEffect(()=>{
    refreshData()
    dispatch(getNombreAVenirThunk())
    dispatch(getPointageByDateThunk({ selectedDate :formatDate(new Date())}))
  },[])

  // const getTodayPointInfos = () => {
  //   setTodayPointageLoading(true);

  //   axios
  //     .get(api + "pointme-infos")
  //     .then((response) => {
  //       if (response.data.success) {
  //           setPointages(response.data.dataAllPointage);
  //           setCountPointages(response.data.countDataAllPointage);
  //           setTodayPointages(response.data.dataDaily);
  //           setTodayPointagesCount(response.data.countDataDaily);

  //           setYesterdayPointages(response.data.dataYesterday);
  //           setYesterdayPointagesCount(response.data.countDataYesterday);
  //           setWeeklyPointages(response.data.dataWeekly);
  //           setWeeklyPointagesCount(response.data.countDataWeekly);
  //           setMonthlyPointages(response.data.dataMonthly);
  //           setMonthlyPointagesCount(response.data.countDataMonthly);
  //           setTodayPointageLoading(false);
  //           setSelected([]);
  //           setGroupyPointageInProgress(false);
  //           setGroupyPointageError(false);
  //           setIsGroupy(false);
  //           setForSelect([]);
  //       }else {
  //           setEmpty(true)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //         setTodayPointageLoading(false);
  //         setEmpty(true);
  //     });
  // };

  // const handlePointMeChange = (e) => {
  //   setPhone(e.target.value)
  //   console.log(phone);
  // };

  const onPointme = (data) => {
    dispatch(submitPointMeArrivingAsync(data.phone))
      .then((response) => {
        if(response.data.success){
          successMsg("Pointage réussi")
          return
        }
        return errorMsg(response.data.message)
      })
    reset();
  }

  // const submitPointMeArriving = (e) => {
  //   e.preventDefault();

  //   setPointMeInProgress(true)

  //   let data = phone;
  //   console.log("data phone",data)

  //   axios
  //     .post(api + "store-pointages", data)
  //     .then((response) => {
  //       if (response.data.success) {
  //         getTodayPointInfos();
  //         setPointMeInProgress(false)
  //         setIsSuccessPointAlert(true)
  //         handleShowAndHideAlert("isSuccessPointAlert");
  //         $("#point-me-formy")[0].reset();
  //       } else {
  //         setPointMeInProgress(false)
  //         setIsSuccessPointAlert(true)
  //         handleShowAndHideAlert("isErrorPointAlert");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setPointMeInProgress(false)
  //       setIsErrorPointAlert(true)
  //       handleShowAndHideAlert("isErrorPointAlert");
  //     });
  // };

  const handleShowAndHideAlert = (input) => {
    setTimeout(() => {
      if (input === "isSuccessPointAlert") {
        setIsSuccessPointAlert(false)
      }
      if (input === "isErrorPointAlert") {
        setIsErrorPointAlert(false)
      }
      if (input === "isSuccessPointDepartureAlert") {
        setIsSuccessPointDepartureAlert(false)
      }
      if (input === "isErrorPointDepartureAlert") {
        setIsErrorPointDepartureAlert(false)
      }
      if (input === "groupyPointageError") {
        this.setState({
          groupyPointageError: false,
        });
        setGroupyPointageError(false)
      }
    }, 5000);
  }

  // const submitPointMeDeparture = (item) => {

  //   setDeparturePointInProgress(true)

  //   var pointItemID = item.id;

  //   axios
  //     .post(api + "store-departure-time/" + pointItemID)
  //     .then((response) => {

  //       setDeparturePointInProgress(false)

  //       if (response.data.success) {
  //         getTodayPointInfos();
  //         setIsSuccessPointDepartureAlert(true)
  //         handleShowAndHideAlert("isSuccessPointDepartureAlert");
  //       } else {
  //         setIsErrorPointDepartureAlert(true)
  //         handleShowAndHideAlert("isErrorPointDepartureAlert");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       setIsErrorPointDepartureAlert(true)
  //       handleShowAndHideAlert("isErrorPointDepartureAlert");
  //     });
  // };

  const submitGroupyPointMeDeparture = (e) => {
    e.preventDefault();
    console.log(selected);
    setGroupyPointageInProgress(true)
    var pointItemIDs = {};
    pointItemIDs["pointage_infos"] = selected;

    console.log(pointItemIDs);

    axios
      .post(api + "groupy-store-departure-time", pointItemIDs)
      .then((response) => {

        setGroupyPointageInProgress(false)

        if (response.data.success) {
          // getTodayPointInfos();
          $(".close").click();
          console.log(response.data.data);
          setIsSuccessPointAlert(true)
          handleShowAndHideAlert("isSuccessPointDepartureAlert");
        } else {
          setGroupyPointageError(true)
          handleShowAndHideAlert("groupyPointageError");
        }
      })
      .catch((error) => {
        console.log(error.message);
        setGroupyPointageError(true)
        setGroupyPointageInProgress(false)
        handleShowAndHideAlert("groupyPointageError");
      });
  };

  const actionsFormatter = (cell, row) => {
    if (row.departure_time === null) {
      return (
        <div className="actions-btn-container">
          {/* <button data-toggle="tooltip" data-placement="top" title="Point Departure" type="button" className="btn btn-danger pm-red-btn" >
            
          </button>  */}
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Point Departure"
            type="button"
            className="btn btn-danger pm-red-btn"
            id="completed"
            onClick={(e) =>
              window.confirm("Voulez-vous valider cette action ?") &&
              // submitPointMeDeparture(row)
              dispatch(submitPointMeDepartureAsync(row))

            }
          >
            {!departurePointInProgress ? (
              <i className="fas fa-walking"></i>
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </div>
      );
    } else {
      return (
        <span
          className="pm-checked-icon-container"
          data-toggle="tooltip"
          data-placement="top"
          title="Arriving and Departure checked"
        >
          <i className="fas fa-check-double"></i>
        </span>
      );
    }
  };

  const handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  const createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  };

  const isSwitchGroupyStatus = () => {
    console.log(dataDaily);
    if (dataDaily !== undefined) {
      dataDaily.forEach((item) => {
        let infos = {
          label: item.userFullname,
          value: item.id,
        };

        forSelect.push(infos);
      });
    }
    setIsGroupy(true)
  };

  //   render() {
  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    btnGroup: createCustomButtonGroup,
  };

  return (
    <div className="component-dashboard">
      {
        !token ? <Redirect to="/" /> :
          <div className="component-dashboard">
            <AppStructure>
              <div className="container-fluid px-xl-5">
                {/* Page content */}
                <section className="py-5">
                  <div className="row dashboard-row">
                    <div className="col-lg-4 mb-5 p-0">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="h6 text-uppercase mb-0">Poyonteel ma</h3>
                        </div>
                        <div className="card-body p-2">
                          <form id="point-me-formy" onSubmit={handleSubmit(onPointme)}>
                            <div className="form-group">
                              <div className="input-group">
                                {/* <div className="input-group-prepend"> */}
                                <span
                                  id="btn-phone"
                                  className="btn btn-primary"
                                >
                                  <i className="icon ion-ios-telephone"></i>
                                </span>
                                {/* </div> */}
                                {/* <div> */}
                                <input
                                  type="tel"
                                  placeholder=""
                                  aria-label="Example text with button addon"
                                  aria-describedby="button-addon1"
                                  className="form-control border"
                                  {...register('phone', {
                                    required: 'Ce champ est obligatoire! *',
                                    pattern: {
                                      value: /((\+221|00221)?)((7[7608][0-9]{7}$)|(3[03][98][0-9]{6}$))/,
                                      message: "le numero telephone n'est pas valide"
                                    }
                                  })}
                                />
                                {/* </div> */}
                              </div>
                            </div>
                            {errors.phone && <p className="errorMsg">{errors.phone.message} </p>}
                            {/* {isErrorPointAlert && (
<div className="alert alert-danger pm-danger-alerty text-center">
Un problème est survenu lors de la tentative de
connexion.Veuillez réessayer plus tard.
</div>
)} */}
                            <div className="form-group">
                              {!pointMeInProgress ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                // onClick={onPointme}
                                >
                                  POINTme
                                </button>
                              ) : (
                                <div className="ui active inverted dimmer">
                                  <div className="ui text loader">
                                    Point in progress
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mb-5 p-0 p-sm-3 pt-sm-0">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="h6 text-uppercase mb-0">
                            Liste des présents
                          </h3>
                        </div>
                        <div className="d-block d-sm-none p-2">
                          {
                            dataDaily?.length ? <>
                              {
                                dataDaily.map(data => {
                                  return <div key={data.id} className="card mt-3">
                                    <div className="card-body p-3">
                                      <div className="d-flex align-items-center gap-2">
                                        <i className="icon ion-ios-person fs-5 d-flex align-items-center"></i>
                                        <h3 className="card-title text-sm mb-0">{data.userFullname}
                                          <span style={{
                                            backgroundColor: "#f03b2e",
                                            color: "#FFF",
                                            padding: "3px 8px",
                                            display: "inline-block",
                                            marginLeft: 5,
                                            borderRadius: 50
                                          }}
                                          >{data.user_status}
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <i className="icon ion-ios-clock d-flex align-items-center"></i>
                                        <p className="card-description">{data.arriving_time}</p>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <i className="icon ion-ios-location d-flex align-items-center"></i>
                                        <p>{data.ville}</p>
                                      </div>
                                    </div>
                                  </div>
                                })
                              }
                            </> : <p className="p-3 text-danger text-center">Pas encore de pointages</p>
                          }
                        </div>
                        <div className="card-body d-none d-sm-block">
                          <div className="col-md-4 offset-md-4 departure-point-container">
                            <button
                              className="btn btn-success groupy-departure-point"
                              data-toggle="modal"
                              data-target="#pointGroupyModal"
                              onClick={isSwitchGroupyStatus}
                            >
                              <i className="fa fa-check"></i>
                            </button>
                          </div>
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            {/* {todayPointageLoading ? (
*/}
                            {!dataDaily ? (
                              <div class="text-center pt-4">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <BootstrapTable
                                data={dataDaily}
                                striped={true}
                                hover={true}
                                condensed={true}
                                multiColumnSort={2}
                                options={options}
                                exportCSV
                                pagination
                              >
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="50"
                                  dataField="id"
                                  isKey={true}
                                  hidden={true}
                                >
                                  {" "}
                                  ID
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="100"
                                  dataField="id"
                                  dataFormat={actionsFormatter}
                                >
                                  Actions
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="200"
                                  dataField="userFullname"
                                  filter={{ type: "TextFilter", delay: 1000 }}
                                >
                                  Prénom et Nom
                                  </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="150"
                                  dataField="arriving_time"
                                  filter={{ type: "TextFilter", delay: 1000 }}
                                >
                                  Heure d'arrivée
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="150"
                                  dataField="departure_time"
                                  filter={{ type: "TextFilter", delay: 1000 }}
                                >
                                  Heure de départ
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataAlign="center"
                                  width="200"
                                  dataField="user_status"
                                  filter={{ type: "TextFilter", delay: 1000 }}
                                >
                                  Statuts
                                </TableHeaderColumn>
                              </BootstrapTable>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </AppStructure>
            <div
              className="modal fade"
              id="pointGroupyModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="pointGroupyModalLabel"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="pointGroupyModalLabel">
                      Pointage au départ groupé
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="ut_form_container">
                      <form id="groupy-point">
                        <div className="col-md-12 input-container">
                          <div className="form-group">
                            {isGroupy ? (
                              <MultiSelect
                                overrideStrings={{
                                  selectSomeItems:
                                    "Choisissez les bakelistes à pointer",
                                  allItemsAreSelected:
                                    "Tous vos bakelistes on été choisis",
                                  selectAll: "Sélectionnez tout",
                                  search: "Rechercher",
                                }}
                                options={forSelect}
                                selected={selected}
                                onSelectedChanged={(selected) =>
                                  setSelected({ selected })
                                }
                              />
                            ) : (
                              "Aucun présent à pointer"
                            )}
                          </div>
                        </div>
                        {groupyPointageError && (
                          <div className="alert alert-danger pm-danger-alerty text-center">
                            Un problème est survenu lors de la tentative de
                            connexion.Veuillez réessayer plus tard.
                          </div>
                        )}
                        <div className="actions-btn-container">
                          <button className="btn btn-danger exit-btn pull-left">
                            <i className="fas fa-times-circle"></i> &nbsp; Annuler
                          </button>
                          {!groupyPointageInProgress ? (
                            <button
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Point Departure"
                              type="button"
                              className="pull-right btn btn-success groupy-submit-btn"
                              id="completed"
                              onClick={submitGroupyPointMeDeparture}
                            >
                              Pointer &nbsp;
                              <i className="fa fa-check"></i>
                            </button>
                          ) : (
                            <button className="btn btn-warning inprogress-btn">
                              Pointage groupé en cours &nbsp;
                              <i className="fa fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <div className="modal-footer">
</div> */}
                </div>
              </div>
            </div>
            {/* Success point form alert */}
            {isSuccessPointAlert === true && (
              <div className="alert alert-success text-center issuccess-alert">
                <i className="fas fa-check-double"></i> &nbsp;&nbsp; Pointage
                effectué avec succès
              </div>
            )}
            {/* Success point form alert */}
            {isSuccessPointDepartureAlert === true && (
              <div className="alert alert-success text-center issuccess-alert">
                <i className="fas fa-check-double"></i> &nbsp;&nbsp; Pointage de
                départ effectué avec succès
              </div>
            )}
            {/* Error alert */}
            {/* {completedFormationRequestError === true && (
<div className="alert alert-danger text-center iserror-alert">
<i className="fas fa-exclamation-triangle"></i> &nbsp;&nbsp; Un
problème est survenu. Réessayer plus tard.
</div>
)} */}
          </div>
      }
    </div>
  );
  //   }
}

export default Dashboard;