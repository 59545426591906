import { createSlice, current } from '@reduxjs/toolkit';
import { getHistoriqueAbsence, getHistoriquePointage } from '../services/getHistoriquePointage';

const initialState = {
  historiqueOptions: [
    { value: 'week', label: 'Semaine' },
    { value: 'month', label: 'Mois' },
    { value: 'year', label: 'Année' }
  ],
  bakelistePresenceHistorique: [],
  pointages: [],
  bakelisteAbsenceHistorique: [],
  absences: [],
  historiqueType: 'presence',
  dateInterval: []
}

export const historiqueSlice = createSlice({
  name: 'historiquePointage',
  initialState,
  reducers:{
    getPointageBetweenTwoDates: (state, {payload}) => {
      // console.log('pointges payload: ', payload);
      state.pointages = state.bakelistePresenceHistorique.filter(pointage => new Date(current(pointage).date_pointage) <= payload[0].endDate && new Date(current(pointage).date_pointage) >= payload[0].startDate)

      state.absences = state.bakelisteAbsenceHistorique.filter(pointage => new Date(current(pointage).date_absence) <= payload[0].endDate && new Date(current(pointage).date_absence) >= payload[0].startDate)
      
    },
    getAbsencesBetweenTwoDates: (state, {payload}) => {
      state.absences = state.bakelisteAbsenceHistorique.filter(pointage => new Date(current(pointage).date_absence) <= payload[0].endDate && new Date(current(pointage).date_absence) >= payload[0].startDate)

      state.pointages = state.bakelistePresenceHistorique.filter(pointage => new Date(current(pointage).date_pointage) <= payload[0].endDate && new Date(current(pointage).date_pointage) >= payload[0].startDate)

    },    
    presenceType: (state) => {
      state.historiqueType = 'presence'
    },
    absenceType: (state) => {
      state.historiqueType = 'absence'
    },
    setDateInterval: (state, {payload}) => {
      state.dateInterval = payload
    },
    deleteDateIntervel: (state) => {
      state.dateInterval = []
    }
  },
  extraReducers: {
    [getHistoriquePointage.pending]: (state) => {
      state.historiqueLoading = true;
    },
    [getHistoriquePointage.fulfilled]: (state, { payload }) => {
      state.historiqueLoading = false
      state.bakelistePresenceHistorique = payload.data.pointages;
      state.pointages = payload.data.pointages;
      return state
    },
    [getHistoriquePointage.rejected]: (state) => {
      state.historiqueLoading = false
      state.bakelistePresenceHistorique = [];
    },

    [getHistoriqueAbsence.pending]: (state) => {
      state.historiqueLoadingAbsence = true;
    },
    [getHistoriqueAbsence.fulfilled]: (state, { payload }) => {
      state.historiqueLoading = false
      state.bakelisteAbsenceHistorique = payload.data.absences;
      state.absences = payload.data.absences;
      return state
    },
    [getHistoriqueAbsence.rejected]: (state) => {
      state.historiqueLoading = false
      state.bakelisteAbsenceHistorique = [];
    },
  }
})

export const {getPointageBetweenTwoDates, getAbsencesBetweenTwoDates, presenceType, absenceType, setDateInterval, deleteDateIntervel} = historiqueSlice.actions
export default historiqueSlice.reducer