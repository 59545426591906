import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../appvars/globalsvariables";
import {
  getBakelisteHistorique,
  getNombreAVenirThunk,
  getPointageByDateThunk,
  getPointageFromDateToThunk
} from "../services/getPointageByDateThunk";

const initialState = {
  pointme: {},
  pointageByDate: [],
  bakelistes: [],
  admins: [],
  coachs: [],
  nombreAVenir: [],
  bakelisteHistorique: [],
  pointageFromDateTo: [],
  pointageFromDateToLoading: false,
  nombreAVenirByDomaine: [],
  submitPointMeArriving: {},
  submitPointMeDeparture: {},
  loading: false,
  nombreAVenirloading: false,
  historiqueLoading: false,
};

export const pointmeSlice = createSlice({
  name: "pointme",
  initialState,
  reducers: {
    allPointMe: (state, { payload }) => {
      state.pointme = payload;
    },
    submitPointMeDeparture: (state, { payload }) => {
      state.pointme = payload;
    },
    getPointageByDate: (state, { payload }) => {
      state.pointageByDate = payload;
      return state;
    },
    setNombreAVenir: (state, { payload }) => {
      state.nombreAVenir = payload;
    }
  },
  extraReducers: {
    [getPointageByDateThunk.pending]: (state) => {
      state.loading = true;
    //   return state;
    },
    [getPointageByDateThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.bakelistes = payload.bakelistes;
      state.admins = payload.admins;
      state.coachs = payload.coachs;
    },
    [getPointageByDateThunk.rejected]: (state) => {
      state.loading = false;
    },

    [getNombreAVenirThunk.pending]: (state) => {
      state.nombreAVenirloading = true;
      state.nombreAVenir = [];
    },
    [getNombreAVenirThunk.fulfilled]: (state, { payload }) => {
      // console.log(payload);
      state.nombreAVenirloading = false;
      state.nombreAVenir = payload;
    },
    [getNombreAVenirThunk.rejected]: (state) => {
      state.nombreAVenirloading = false;
      state.nombreAVenir = [];
    },

    [getBakelisteHistorique.pending]: (state) => {
      state.historiqueLoading = true;
      state.bakelisteHistorique = [];
    },
    [getBakelisteHistorique.fulfilled]: (state, { payload }) => {
      // console.log(payload);
      state.historiqueLoading = false;
      state.bakelisteHistorique = payload;
    },
    [getBakelisteHistorique.rejected]: (state) => {
      state.historiqueLoading = false;
      state.bakelisteHistorique = [];
    },

    [getPointageFromDateToThunk.pending]: (state) => {
      state.pointageFromDateToLoading = true;
    },
    [getPointageFromDateToThunk.fulfilled]: (state, { payload }) => {
      state.pointageFromDateToLoading = false
      state.pointageFromDateTo = payload.pointages;
      return state
    },
    [getPointageFromDateToThunk.rejected]: (state) => {
      state.pointageFromDateToLoading = false
      state.pointageFromDateTo = [];
    },


  }
});

export const allPointMeAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(API + "pointme-infos");
    dispatch(allPointMe(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const submitPointMeArrivingAsync = (data) => async (dispatch) => {
  let formData = new FormData(); //formdata object
  formData.append("phone", data);
  try {
    const res = await axios.post(API + "store-pointages", formData);
    dispatch(allPointMeAsync());
    return res;
  } catch (error) {
    return error;
  }
};

export const submitPointMeDepartureAsync = (data) => async (dispatch) => {
  try {
    const pointItemID = data.id;
    const res = await axios.post(API + "store-departure-time/" + pointItemID);
    dispatch(allPointMeAsync());
  } catch (error) {
    console.log(error);
  }
};

// export const getPointageByDateAsync = ({ role, selectedDate }) => async dispatch => {

//     try {
//         const { data } = await axios.get(API + `get-pointage-by-date/${selectedDate}`)
//         const { data: nombreAVenir } = await axios.get(API + "pointme-infos/nombre_a_venir")

//         dispatch(setNombreAVenir(nombreAVenir))

//         if (role === "bakelistes") {
//             dispatch(getPointageByDate(data.bakelistes))
//         }
//         if (role === "admins") {
//             dispatch(getPointageByDate(data.admins))
//         }
//         if (role === "coachs") {
//             dispatch(getPointageByDate(data.coachs))
//         }

//     } catch (error) {
//         console.log(error)
//     }
// }

export const {
  allPointMe,
  submitPointMeArriving,
  submitPointMeDeparture,
  getPointageByDate,
  setNombreAVenir,
  setLoading
} = pointmeSlice.actions;
export default pointmeSlice.reducer;
