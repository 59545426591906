import React, { Component, useState } from "react";
import "./grapheAbsence.css";
import axios from "axios";
import API from "./../../appvars/globalsvariables";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import WeeklyPointageChart from "../weeklyPointageChart/index";
import fr from "date-fns/locale/fr";
import {Chart} from "chart.js";
let myLineChart;
let mySecondChart;

//--Chart Style Options--//
// Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
// Chart.defaults.global.legend.display = false;
// Chart.defaults.global.elements.line.tension = 0;

export const withHooksHOC = (Component) => {
  return (props) => {
    const [startDate, setStartDate] = useState(moment().subtract("days", 7)._d);
    const [endDate, setEndDate] = useState(new Date());

    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      locale: fr,
    };

    function handleSelect(ranges) {
      console.log(ranges, ranges["selection"].startDate);
      setStartDate(ranges["selection"].startDate);
      setEndDate(ranges["selection"].endDate);
    }

    return (
      <Component
        {...props}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        endDate={endDate}
        startDate={startDate}
      />
    );
  };
};

class grapheAbsence extends React.Component {
  chartRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      api: API.API,
    };
  }

  componentDidUpdate() {
    this.makeApiCall();
  }

  makeApiCall = () => {
    const fromDate = moment(this.props.startDate).format("YYYY-MM-DD");
    const toDate = moment(this.props.endDate).format("YYYY-MM-DD");
    let formData = new FormData(); //formdata object
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    axios
      .post(this.state.api + "get_absences_in_date", formData)
      .then((response) => {
        this.getChartData(response.data.data);
      })
      .catch((err) => console.log(err));
  };
  getChartData = (data) => {
    const absencesdata = [];
    const absenceslabel = [];
    Object.keys(data).map((item, i) => {
      return absenceslabel.push(item), absencesdata.push(data[item]);
    });
    const myChartRef = this.chartRef.current.getContext("2d");

    if (typeof myLineChart !== "undefined") {
      myLineChart.destroy();
    }
    myLineChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: absenceslabel,
        datasets: [
          {
            label: "Absence par Jour",
            data: absencesdata,
            fill: true,
            borderColor: "red",
            backgroundColor: "#ff00007a",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        events: false,
        tooltips: {
          enabled: false,
        },
        hover: {
          animationDuration: 0,
        },
        animation: {
          duration: 1,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              // Chart.defaults.global.defaultFontSize,
              // Chart.defaults.global.defaultFontStyle,
              // Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          },
        },
      },
    });
  };

  render() {
    return (
      <div className="component-statistique-view">
        {/* Page content */}
        <section className="py-5">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <div className="card bk_orange_bg">
                <div className="card-header">
                  <h3 className="h6 text-uppercase mb-0">
                    Graphe des Absents du{" "}
                    <span class="date">
                      {moment(this.props.startDate).format("DD-MM-YYYY")}
                    </span>{" "}
                    au{" "}
                    <span class="date">
                      {moment(this.props.endDate).format("DD-MM-YYYY")}{" "}
                    </span>{" "}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-md-12 delay-table-col">
                    {/* TODO */}
                    <div className="graphContainer">
                      <canvas id="myChart" ref={this.chartRef} />
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex flex-wrap">
                <DateRangePicker
                  locale={fr}
                  ranges={[this.props.selectionRange]}
                  onChange={this.props.handleSelect}
                  isOutsideRange={() => null}
                  moveRangeOnFirstSelection={false}
                  retainEndDateOnFirstSelection={true}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withHooksHOC(grapheAbsence);

// export default connect(
//     ({ statistiqueView }) => ({ ...statistiqueView }),
//     dispatch => bindActionCreators({ ...statistiqueViewActions }, dispatch)
//   )( statistiqueView );
