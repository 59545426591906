import React, { Component, useEffect, useState } from "react";
import "./bakelistePointages.css";
import {
  Database,
  Users,
  User,
  UserCheck,
  Code,
  Figma,
  Map,
  MapPin,
  Command,
} from "react-feather";
import axios from "axios";
import { API } from "./../../appvars/globalsvariables";
import { timers } from "jquery";
import Footer from "../footer/footer";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import Statistique from "../statistique/statistique";
import { Link } from "react-router-dom";
import AppStructure from "../AppStructure";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as bakelistePointagesActions from "../../store/bakelistePointages/actions";

const BakelistePointagest = () => {

  const api = API;
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [pointagedkr, setPointagedkr] = useState(0)
  const [pointagesdev, setPointagesdev] = useState(0)
  const [pointagesdesign, setPointagesdesign] = useState(0)
  const [pointagethies, setPointagethies] = useState(0)
  const [pointagesgp, setPointagesgp] = useState(0)
  const [pointagesmd, setPointagesmd] = useState(0)
  const [pointagelabe, setPointagelabe] = useState(0)
  const [pointagesDaily, setPointagesDaily] = useState(0)


  useEffect(() => {
    getCOuntPointages()
  }, [])

  const getCOuntPointages = () => {
    setIsPageLoading(true)

    axios.get(api + "pointme-infos-bakelistes").then((response) => {
      isPageLoading(false)
      setPointagesDaily(response.data.pointagesDaily)
      setPointagedkr(response.data.pointagedkr)
      setPointagethies(response.data.pointagethies)
      setPointagelabe(response.data.pointagelabe)
      setPointagesdev(response.data.pointagesdev)
      setPointagesdesign(response.data.pointagesdev)
      setPointagesmd(response.data.pointagesmd)
      setPointagesgp(response.data.pointagesgp)
    });
  };



  if (isPageLoading) {
    return (
      <div className="component-gestion-absence-retard">
        {/* Header render */}

        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            {/* Page content */}
            <section className="py-5">
              <div className="row dashboard-row">
                <div className="col-lg-6 mb-5">
                  <div className="card">
                    <h3 className="page-title-green">
                      Pointages d'Aujourd'hui
                    </h3>

                    <div className="card-body">In progress</div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="ui active inverted dimmer">
            <div className="ui text loader">Chargement en cours</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <AppStructure>
      <div className="container-fluid px-xl-5">
        {" "}
        <h3 className="page-title-green mt-5 mb-3">
          Pointages Bakelistes par zone
        </h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Database color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Total</span>
              <span class="value">
                <span>{pointagesDaily}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-primary">
              <i>
                <Map color="orange" size={48} />{" "}
              </i>
              <span class="heading">Dakar</span>
              <span class="value">
                <span>{pointagedkr}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <MapPin color="red" size={48} />
              </i>{" "}
              <span class="heading">Thies</span>
              <span class="value">
                <span>{pointagethies}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <Map color="green" size={48} />{" "}
              </i>
              <span class="heading">Labe</span>
              <span class="value">
                <span>{pointagelabe}</span>
              </span>
            </div>
          </div>
        </div>
        {/* Pointages d'hier */}
        <h3 className="page-title-green mt-5 mb-3">
          Pointages Bakelistes par domaine
        </h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Code color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Developpement</span>
              <span class="value">
                <span>{pointagesdev}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-primary">
              <i>
                <Figma color="orange" size={48} />{" "}
              </i>
              <span class="heading">Design</span>
              <span class="value">
                <span>{pointagesdesign}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <User color="red" size={48} />
              </i>{" "}
              <span class="heading">Marketing Digital</span>
              <span class="value">
                <span>{pointagesmd}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <Command color="green" size={48} />{" "}
              </i>
              <span class="heading">Gestion de Projet</span>
              <span class="value">
                <span>{pointagesgp}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </AppStructure>
  );
}

export default BakelistePointagest;
