import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../../appvars/globalsvariables"
import { getAbsenceFromDateToThunk } from "../services/getPointageByDateThunk"





const initialState = {
    absence: {},
    absenceBakeliste : [],
    absenceFromDateToLoading: false,
    absenceFromDateTo: [],
}

export const absenceSlice = createSlice({
    name:"absence",
    initialState,
    reducers:{
        getAllAbsence:(state,{payload})=>{
            state.absence = payload
        },
        getBakeliteAbsencePerMonth:(state,{payload})=>{
            state.absenceBakeliste = payload
        }
    },
    extraReducers: {
        [getAbsenceFromDateToThunk.pending]: (state) => {
            state.absenceFromDateToLoading = true;
          },
          [getAbsenceFromDateToThunk.fulfilled]: (state, { payload }) => {
            state.absenceFromDateToLoading = false
            state.absenceFromDateTo = payload.absences;
            return state
          },
          [getAbsenceFromDateToThunk.rejected]: (state) => {
            state.absenceFromDateToLoading = false
            state.absenceFromDateTo = [];
          },
    }
})


export const getAllAbsenceAsync = ()=> async dispatch=>{
    try {
        const res = await axios.get(API+ "daily-absence-list")
        dispatch(getAllAbsence(res.data))
    } catch (error) {
        console.log(error)
    }
}

export const getBakeliteAbsencePerMonthAsync = ()=> async dispatch=>{
    try {
        const res = await axios.get(API+ "count-absence-per-month")
        dispatch(getBakeliteAbsencePerMonth(res.data))
    } catch (error) {
        console.log(error)
    }
}

export const { getAllAbsence , getBakeliteAbsencePerMonth} = absenceSlice.actions
export default absenceSlice.reducer
