import React from 'react'

const PresenceDetailsItem = ({label1, text1, label2, text2, isImportant1, isImportant2}) => {
  return (
    <div className='row d-flex justify-content-center mt-3 border-bottom py-2'>
        <div className='col-md-6 col-12 mt-md-0 mt-2'>
            <span className='span_detail--label'>{label1}</span> : <span className={isImportant1 ? "span_detail--text" : null}>{text1}</span>
        </div>
        <div className='col-md-6 col-12 mt-md-0 mt-2'>
            <span className='span_detail--label'>{label2}</span> : <span className={isImportant2 ? "span_detail--text" : null}>{text2}</span>
        </div>
    </div>
  )
}

export default PresenceDetailsItem