import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../appvars/globalsvariables";

export const getHistoriquePointage = createAsyncThunk(
  'historique/getHistoriquePointage',
   async (arg, {rejectWithValue}) => {
    try {
      const { data } = await axios.get(
        API + `bakeliste-pointage/${arg.userId}`
        );

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
})

export const getHistoriqueAbsence = createAsyncThunk(
  'historique/getHistoriqueAbsence',
   async (arg, {rejectWithValue}) => {
    try {
        const { data } = await axios.get(
          API + `bakeliste-absence/${arg.userId}`
          );

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
})