import React, { Component, useEffect, useState } from 'react';
import './absenceCounter.css';
import Header from './../header/header';
import Sidebar from './../sidebar/sidebar';
import Footer from './../footer/footer';
import { API } from './../../appvars/globalsvariables';
import axios from 'axios';
// import { NavLink } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import AppStructure from '../AppStructure';


const AbsenceCounter = () => {

  const api = API
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [currentMonthAbsence, setCurrentMonthAbsence] = useState([])
  const [bakelistes, setBakelistes] = useState([])
  const [lastMonthAbsence, setLastMonthAbsence] = useState([])
  const [absenceCMInfos, setAbsenceCMInfos] = useState([])
  const [absenceLMInfos, setAbsenceLMInfos] = useState([])
  const [isEmptyReturnData, setIsEmptyReturnData] = useState([])
  const absenceBakeliste = useSelector(state => state.absence.absenceBakeliste.data)

  // useEffect(()=>{
  //     onGetAbsencesBakeliste()
  // },[])

  const onGetAbsencesBakeliste = () => {
    setIsPageLoading(true)

    axios.get(api + 'count-absence-per-month')
      .then(response => {
        setIsPageLoading(false)
        if (response.data.success && !response.data.empty) {
          setBakelistes(response.data.data)

        } else if (response.data.success && !response.data.empty) {
          console.log(response)
          setIsEmptyReturnData(true)
        }
      })
      .catch(error => {
        console.log(error.message)
        setIsPageLoading(false)
      })
  }


  const handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  const createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  const createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
      </ButtonGroup>
    );
  }

  const fullNameFormatter = (cell, row) => {
    return (
      <span className="fullname_bakeliste"> {row.bakelisteFullname} </span>
    )
  }

  const countFormatter = (cell, row) => {
    return (
      <span className="count_absence_cell badge badge-success bg-vred"> {row.countCurrentMonthAbsence} </span>
    )
  }

  const countLMFormatter = (cell, row) => {
    if (cell < 3) {
      return (
        <span className="count_absence_cell badge badge-success bg-vred"> {row.countLastMonthAbsence} </span>
      )
    } else {
      return (
        <span className="count_absence_4 badge badge-danger"> {row.countLastMonthAbsence} </span>
      )
    }
  }

  const onRowClick = (row) => {

    setAbsenceCMInfos(row.currentMonthAbsence)
    setAbsenceLMInfos(row.lastMonthAbsence)
    $('.open-infos-modal').click();
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    btnGroup: createCustomButtonGroup,
    noDataText: 'Aucune donnée',
  };

  if (isPageLoading) {
    return (
      <div className="component-absence-counter">
        {/* Header render */}
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar render */}
          <Sidebar />
          <div className="page-holder w-100 d-flex flex-wrap">
            <div className="container-fluid px-xl-5">
              {/* Page content */}
              <section className="py-5">
                <div className="row dashboard-row">
                  <div className="col-lg-6 mb-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="h6 text-uppercase mb-0">Nombre d'absence du mois en cours</h3>
                      </div>
                      <div className="card-body">
                        In progress
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="h6 text-uppercase mb-0">Nombre d'absence du mois précédent</h3>
                      </div>
                      <div className="card-body">
                        In progress
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </div>
            {/* Footer render */}
            <Footer />
          </div>
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Chargement en cours</div>
          </div>
        </div>
      </div>

    )
  }

  return (
    <div className="component-absence-counter">
      <AppStructure>
        <div className="container-fluid px-xl-5">
          {/* Page content */}
          <section className="py-5">
            <div className="row dashboard-row">
              <div className="col-lg-12 mb-5">
                <div className="card">
                  <div className="card-header bk_green_bg">
                    <h3 className="h6 text-uppercase mb-0">Liste des absences</h3>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="yat_absence_tabs" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link count_absence_tab_link active" id="ta_matin" data-toggle="tab" href="#current_month_absence_link" role="tab" aria-controls="current_month_absence_link" aria-selected="true">Mois en cours </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link count_absence_tab_link" id="ta_aprem" data-toggle="tab" href="#last_month_absence_link" role="tab" aria-controls="last_month_absence_link" aria-selected="true">Mois précédent</a>
                      </li>
                    </ul>
                    <div className="tab-content" id="absenceTabContent">
                      <div className="tab-pane fade show active" id="current_month_absence_link" role="tabpanel" aria-labelledby="today_absence_matin_link-tab">
                        {/* todo */}
                        <div className="col-md-12 ta-table-col">
                          <BootstrapTable data={absenceBakeliste}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            exportCSV
                            pagination>

                            <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                            <TableHeaderColumn dataAlign="center"
                              width='150'
                              dataField='bakelisteFullname'
                              dataFormat={fullNameFormatter.bind(this)}
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Nom complet du bakeliste</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='100'
                              dataField='countCurrentMonthAbsence'
                              dataFormat={countFormatter.bind(this)}
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Nombre d'absences</TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                          </BootstrapTable>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="last_month_absence_link" role="tabpanel" aria-labelledby="today_absence_aprem_link-tab">
                        {/* todo */}
                        <div className="col-md-12 ta-table-col">
                          <BootstrapTable data={bakelistes}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            exportCSV
                            pagination>

                            <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='100'
                                        dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)} >
                                          Actions</TableHeaderColumn> */}
                            <TableHeaderColumn dataAlign="center"
                              width='150'
                              dataField='bakelisteFullname'
                              dataFormat={fullNameFormatter}
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Nom complet du bakeliste</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='100'
                              dataField='countLastMonthAbsence'
                              dataFormat={countLMFormatter}
                              filter={{ type: 'TextFilter', delay: 1000 }}
                            >Nombre d'absences</TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='user_status'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Statuts</TableHeaderColumn> */}
                            {/* <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='arriving_time'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                      >Heure d'arrivée</TableHeaderColumn> */}
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </AppStructure>

      <div className="dyn_btn_container">
        <button className="open-infos-modal" data-toggle="modal" data-target="#detailsAbsenceInfosModal">Open Infos Modal</button>
      </div>

      <div className="modal fade" id="detailsAbsenceInfosModal" tabIndex="-1" role="dialog" aria-labelledby="detailsAbsenceInfosModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="detailsAbsenceInfosModalLabel">Détails</h4>
            </div>
            <div className="modal-body">
              <div className="row modal-row">
                <div className="col-md-12 rating-item">

                  {/* {this.state.absenceInfos.map((item, index) =>
                                  <div key={index}> { item.absenceFrenchDateFormat } </div>
                              )} */}
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsenceCounter;