// LOCAL
// const API = "http://127.0.0.1:8000/api/";

// ONLINE
//  const API = 'https://si.bakeli.tech/api/';

const API = "https://via-api.bakeli.tech/api/";

module.exports = {
  API: API,
};
