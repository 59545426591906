import AbsenceCounter from "../components/absenceCounter/absenceCounterRefact";
import BakelistePointagest from "../components/bakelistePointages/bakelistePointages";
import Connexion from "../components/connexion/ConnexionRefact"
import Dashboard from "../components/dashboard/dashboardRefact";
import GestionAbsenceRetard from "../components/gestionAbsenceRetard/gestionAbsenceRetardRefact";
import AdminPresenceView from "../components/presences/admin/AdminPresenceView";
import BakelistePresenceView from "../components/presences/bakeliste/BakelistePresenceView";
import CoachPresenceView from "../components/presences/coach/CoachPresenceView";
import statistiqueView from "../components/statistiqueView/statistiqueView";
import { ADMIN_PRESENCE_PATH, BAKELISTES_PRESENCE_PATH, COACH_PRESENCE_PATH, COUNT_ABSENCE_DASHBOARD_PATH, DASHBOARD_PATH, GESTION_ABSENCE_RETARD_PATH, HOME_PATH, STATISTIQUES_BAKELISTES_PATH, STATISTIQUES_PATH } from "./paths";

export const routes = [
  {
    path: HOME_PATH,
    component: Connexion,
  },
  {
    path: DASHBOARD_PATH,
    component: Dashboard,
  },
  {
    path: GESTION_ABSENCE_RETARD_PATH,
    component: GestionAbsenceRetard,
  },
  {
    path: COUNT_ABSENCE_DASHBOARD_PATH,
    component: AbsenceCounter,
  },
  {
    path: STATISTIQUES_PATH,
    component: statistiqueView,
  },
  {
    path: STATISTIQUES_BAKELISTES_PATH,
    component: BakelistePointagest,
  },
  {
    path: BAKELISTES_PRESENCE_PATH,
    component: BakelistePresenceView,
  },
  {
    path: ADMIN_PRESENCE_PATH,
    component: AdminPresenceView,
  },
  {
    path: COACH_PRESENCE_PATH,
    component: CoachPresenceView,
  },
]