import React from 'react'
import AppStructure from '../../AppStructure'
import PresenceView from '../PresenceView'

const BakelistePresenceView = () => {

    return (
        <AppStructure>
            <PresenceView status="bakelistes" role="bakelistes" />
        </AppStructure>
    )
}

export default BakelistePresenceView
