import React, { Component, useState } from "react";
import "./presentAbsentGraphe.css";
import axios from "axios";
import API from "../../appvars/globalsvariables";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import {
  Database,
  Users,
  User,
  UserCheck,
  Code,
  Figma,
  Map,
  MapPin,
  Command,
} from "react-feather";
import moment from "moment";
import fr from "date-fns/locale/fr";
import {Chart} from "chart.js";
let myLineChart;
let mySecondChart;

//--Chart Style Options--//
// Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
// Chart.defaults.global.legend.display = false;
// Chart.defaults.global.elements.line.tension = 0;

export const withHooksHOC = (Component) => {
  return (props) => {
    const [startDate, setStartDate] = useState(moment().subtract("days", 7)._d);
    const [endDate, setEndDate] = useState(new Date());

    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      locale: fr,
    };

    function handleSelect(ranges) {
      console.log(ranges, ranges["selection"].startDate);
      setStartDate(ranges["selection"].startDate);
      setEndDate(ranges["selection"].endDate);
    }

    return (
      <Component
        {...props}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        endDate={endDate}
        startDate={startDate}
      />
    );
  };
};

class presentAbsentGraphe extends React.Component {
  allchartRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      api: API.API,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().subtract("days", 7).format("YYYY-MM-DD"),
      graphLoading: false,
      bartype: "bar",
      dataPresence: [],
      dataAbsences: [],
    };
  }

  componentDidMount() {
    this.onGetListPointageParJour();
  }
  componentDidUpdate() {
    this.buildAllChart(this.state.dataPresence, this.state.dataAbsences);
    console.log(
      this.state.bartype,
      this.state.dataPresence,
      this.state.dataAbsences
    );
  }

  onGetListPointageParJour = () => {
    // const fromDate = moment(this.props.startDate).format("YYYY-MM-DD");
    // const toDate = moment(this.props.endDate).format("YYYY-MM-DD");
    console.log("ok", this.state.endDate, this.state.startDate);
    this.setState({ graphLoading: true });
    let formData = new FormData(); //formdata object
    formData.append("fromDate", this.state.startDate);
    formData.append("toDate", this.state.endDate);
    axios
      .post(this.state.api + "get_presences_absences_in_date", formData)
      .then((response) => {
        this.setState({
          graphLoading: false,
          dataAbsences: response.data.dataAbsences,
          dataPresence: response.data.dataPresence,
        });

        this.buildAllChart(
          response.data.dataPresence,
          response.data.dataAbsences
        );
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isErrorRequest: true,
        });
      });
  };

  buildAllChart = (presences, absences) => {
    const absencesdata = [];
    const absenceslabel = [];
    const allmychartRef = this.allchartRef.current.getContext("2d");
    Object.keys(absences).map((item, i) => {
      console.log("i", item);
      return absenceslabel.push(item), absencesdata.push(absences[item]);
    });
    const cwlpday = [];
    const cwlpdata = [];

    Object.keys(presences).map((item, i) => {
      return cwlpday.push(item), cwlpdata.push(presences[item]);
    });
    // const { data, average, labels } = this.props;

    if (typeof mySecondChart !== "undefined") {
      mySecondChart.destroy();
    }
    mySecondChart = new Chart(allmychartRef, {
      type: this.state.bartype,
      data: {
        //Bring in data
        labels: absenceslabel,
        datasets: [
          {
            label: "Presents",
            data: cwlpdata,
            fill: true,
            borderColor: "red",
            backgroundColor: "#04928585",
          },
          {
            type: this.state.bartype,
            label: "Absents",
            data: absencesdata,
            backgroundColor: "#ff00007a",
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        events: false,
        tooltips: {
          enabled: false,
        },
        hover: {
          animationDuration: 0,
        },
        animation: {
          duration: 1,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              // Chart.defaults.global.defaultFontSize,
              // Chart.defaults.global.defaultFontStyle,
              // Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          },
        },
      },
    });
  };
  updateDate = (e) => {
    e.preventDefault();
    if (e.target.checked) {
      this.setState({ startDate: e.target.value });
      this.onGetListPointageParJour();

      console.log("enddate", e.target.value);
    }
  };

  updateChart = (e) => {
    console.log("e", e);
    this.setState({ bartype: e.target.value });
    // this.onGetListPointageParJour();

    // this.buildAllChart(this.state.dataPresence, this.state.dataAbsences);

    // this.onGetListPointageParJour();
  };
  render() {
    return (
      <div className="component-statistique-view">
        {/* Page content */}
        <section className="py-5">
          <div className="row">
            {/* <div class=" offset-3 d-flex flex-wrap">
              <DateRangePicker
                locale={fr}
                ranges={[this.props.selectionRange]}
                onChange={this.props.handleSelect}
                isOutsideRange={() => null}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={true}
                direction="vertical"
                scroll={{ enabled: true }}
              />
            </div> */}

            <div className="col-lg-12 mb-5 mt-3">
              {this.state.graphLoading ? (
                <div className="card">
                  <div className="card-header">
                    <h3 className="h6 text-uppercase mb-0">
                      Graphe des Presents/Absents{" "}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12 delay-table-col">
                      {/* TODO */}
                      <div className="graphContainer">
                        <canvas id="chartAll" ref={this.allchartRef} />
                      </div>{" "}
                    </div>
                  </div>
                  <div className="ui active inverted dimmer">
                    <div className="ui text loader">Chargement en cours</div>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-header row">
                    <div className="col-md-4">
                      <h3 className="h6 text-uppercase mb-0">
                        Graphe des Presents/Absents{" "}
                        {/* <span class="date">
                      {moment(this.props.startDate).format("DD-MM-YYYY")}
                    </span>{" "}
                    au{" "}
                    <span class="date">
                      {moment(this.props.endDate).format("DD-MM-YYYY")}{" "}
                    </span>{" "} */}
                      </h3>
                    </div>
                    <div className="col-md-4">
                      <div id="tab" class="btn-group btn-group-justified">
                        <div
                          className="filter-text"
                          title="Semaine en cours!"
                          data-placement="bottom"
                        >
                          <input
                            type="radio"
                            name="date"
                            value={moment()
                              .subtract("days", 7)
                              .format("YYYY-MM-DD")}
                            checked={
                              this.state.startDate ===
                              moment().subtract("days", 7).format("YYYY-MM-DD")
                            }
                            onChange={this.updateDate}
                          />
                          en cours
                        </div>
                        <div
                          className="filter-text"
                          title="Les 2 dernières semaines!"
                          data-placement="bottom"
                        >
                          <input
                            type="radio"
                            name="date"
                            value={moment()
                              .subtract("days", 15)
                              .format("YYYY-MM-DD")}
                            checked={
                              this.state.startDate ===
                              moment().subtract("days", 15).format("YYYY-MM-DD")
                            }
                            onChange={this.updateDate}
                          />
                          2 semaines
                        </div>
                        <div
                          className="filter-text"
                          title="Les 3 dernières semaines!"
                          data-placement="bottom"
                        >
                          <input
                            type="radio"
                            name="date"
                            value={moment()
                              .subtract("days", 21)
                              .format("YYYY-MM-DD")}
                            checked={
                              this.state.startDate ===
                              moment().subtract("days", 21).format("YYYY-MM-DD")
                            }
                            onChange={this.updateDate}
                          />
                          3 semaines
                        </div>
                        <div
                          className="filter-text"
                          title="Les 4 dernières semaines!"
                          data-placement="bottom"
                        >
                          <input
                            type="radio"
                            name="date"
                            value={moment()
                              .subtract("days", 30)
                              .format("YYYY-MM-DD")}
                            checked={
                              this.state.startDate ===
                              moment().subtract("days", 30).format("YYYY-MM-DD")
                            }
                            onChange={this.updateDate}
                          />{" "}
                          4 semaines
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="select">
                        <select
                          name="slct"
                          id="slct"
                          onChange={this.updateChart}
                        >
                          <option value="bar" defaultValue>
                            BAR
                          </option>
                          <option value="line">LINE</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div class="tab-content">
                    <div class="tab-pane active" id="prices">
                      Prices content
                    </div>
                    <div class="tab-pane" id="features">
                      Features Content
                    </div>
                    <div class="tab-pane" id="requests">
                      Requests Content
                    </div>
                    <div class="tab-pane" id="contact">
                      Contact Content
                    </div>
                  </div>
                </div> */}
                  <div className="card-body">
                    <div className="col-md-12 delay-table-col">
                      {/* TODO */}
                      <div className="graphContainer">
                        <canvas id="chartAll" ref={this.allchartRef} />
                      </div>{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withHooksHOC(presentAbsentGraphe);

// export default connect(
//     ({ statistiqueView }) => ({ ...statistiqueView }),
//     dispatch => bindActionCreators({ ...statistiqueViewActions }, dispatch)
//   )( statistiqueView );
