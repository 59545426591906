function dataPresence(nombreAVenir, jours, range, categoriesXaxis, yAxis, yAxisAbsence) {
  const locale = {
    sunday: "Di",
    monday: "Lu",
    tuesday: "Ma",
    wednesday: "Mer",
    thursday: "Jeu",
    friday: "Ven",
    saturday: "Sa",
    ok: "OK",
    today: "Auj.",
    yesterday: "Hier",
    last7Days: "7 dernier jours",
    hours: "Heurs",
    minutes: "Minutes",
    seconds: "Secondes"
  };

  const series = [
    {
      name: "Nombre à venir",
      // data: [15, 25, 10, 26, 14],
      data:
        nombreAVenir.length !== 0
          ? jours.map((jour) => nombreAVenir[jour]?.length)
          : [0, 0, 0, 0]
    },
    {
      name: "Nombre présent",
      data: yAxis
    },
    {
      name: "Nombre absent",
      data: yAxisAbsence
    }
  ];

  const options = {
    xaxis: {
      categories: !range.length ? categoriesXaxis : range
    },
    yaxis: {
      categories: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"]
    },

    colors: ["#ff9800", "#009688", "#dc3545"], //! couleurs pour chaque graphe
    stroke: {
      curve: "smooth"
    },
    chart: {
      id: "bakeliste-chart",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 1000,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      background: "#ffeff"
      // background: '#ff9800',
    },

    foreColor: "#373d3f",

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.1,
        gradientToColors: "rgb(0 0 0 / .1)",
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: []
      },
      // type: "pattern",
      pattern: {
        style: "verticalLines",
        width: 1,
        height: 5,
        strokeWidth: 1
      }
    },

    dataLabels: {
      style: {
        colors: ["#000", "#fff", "#fff"] // ! couleur du text sur les graphes
      }
    },

    markers: {
      colors: ["#000", "#000", "#000"] // ! pour les points
    },

    theme: {
      monochrome: {
        enabled: false,
        color: "#000",
        shadeTo: "light",
        shadeIntensity: 1
      }
    }
  };

  return { series, options, locale };
}

export default dataPresence;
