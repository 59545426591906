import React from "react";

export const StatistiqueCard = ({ icon, title, isLoading, value }) => {
  return (
    <div class="col-lg-3 col-xs-6">
      <div class="rad-info-box rad-txt-success">
        <i>{icon}</i>
        <span class="heading">{title}</span>
        <span class="value">
          {isLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <span>{value}</span>
          )}
        </span>
      </div>
    </div>
  );
};
