import todayName from "./todayName";
import dayjs from "dayjs";
import "dayjs/locale/fr";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const getDaysWithNumberPointage = (fullDates) => {
  const goodDays = []
  fullDates.forEach((date) => {
    const day = todayName(date.date);
    if (day !== "samedi") {
      if (day !== "dimanche") {
        goodDays.push({date: dayjs(new Date(date.date)).format("DD MMM"), nombre: date.nombre});
      }
    }
  });
  
  return goodDays
}

export default getDaysWithNumberPointage