import React from 'react'
import Footer from './footer/footer'
import Header from './header/header'
import Sidebar from './sidebar/sidebar'

const AppStructure = ({ children }) => {
  return (
    <div className="component-gestion-absence-retard">
      <Header />
      <div className="d-flex align-items-stretch">
        <div className='d-none d-sm-block'>
          <Sidebar />
        </div>
        <div style={{}} className="page-holder w-100 p-sm-5 p-3">
          {children}
        </div>
      </div>
      <Footer />
    </div >
  )
}

export default AppStructure