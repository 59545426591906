import React, { Component, useEffect, useState } from "react";
import "./statistiqueView.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import Statistique from "../statistique/StatistiqueRefact";
import axios from "axios";
import API from "./../../appvars/globalsvariables";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import WeeklyPointageChart from "../weeklyPointageChart/index";
import fr from "date-fns/locale/fr";
import {Chart} from "chart.js";
import GrapheAbsence from "../grapheAbsence/grapheAbsence";
import PresentAbsentGraphe from "../presentAbentGraphe/presentAbsentGraphe";
import AppStructure from "../AppStructure";
let myLineChart;
let mySecondChart;

//--Chart Style Options--//
// Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
// Chart.defaults.global.legend.display = false;
// Chart.defaults.global.elements.line.tension = 0;

export const withHooksHOC = (Component) => {
  return (props) => {
    const [startDate, setStartDate] = useState(moment().subtract("days", 7)._d);
    const [endDate, setEndDate] = useState(new Date());

    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      locale: fr,
    };
    function handleSelect(ranges) {
      console.log(ranges, ranges["selection"].startDate);
      setStartDate(ranges["selection"].startDate);
      setEndDate(ranges["selection"].endDate);
    }

    return (
      <Component
        {...props}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        endDate={endDate}
        startDate={startDate}
      />
    );
  };
};

const StatistiqueView =()=> {
  const chartRef = React.createRef();
  const allchartRef = React.createRef();
  const [startDate, setStartDate] = useState(moment().subtract("days", 7)._d);
  const [endDate, setEndDate] = useState(new Date());

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
    locale: fr,
  };
  function handleSelect(ranges) {
    console.log(ranges, ranges["selection"].startDate);
    setStartDate(ranges["selection"].startDate);
    setEndDate(ranges["selection"].endDate);
  }

  const api = API
  const [countWeeklyAbsences,setCountWeeklyAbsences] = useState([])
  const [countWeeklyPointages,setCountWeeklyPointages] = useState([])
  const [cwlpday,setCwlpday] = useState([])
  const [cwlpdata,setCwlpdata] = useState([])
  const [focusedInput,setFocusedInput] = useState(null)



  useEffect(()=>{
    makeApiCall()
  },[])

  const makeApiCall = () => {
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    let formData = new FormData(); //formdata object
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    axios
      .post(api + "get_pointages_in_date", formData)
      .then((response) => {
        getChartData(response.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getChartData = (data) => {
    const cwlpday = [];
    const cwlpdata = [];

    Object.keys(data).map((item, i) => {
      return cwlpday.push(item), cwlpdata.push(data[item]);
    });
    setCwlpdata(cwlpdata)
    setCwlpday(cwlpday)
  };

    return (
      <AppStructure>
        <div className="d-flex align-items-stretch">
            <div className="page-holder w-100 d-flex flex-wrap">
              <div className="container-fluid px-xl-5">
                <Statistique />

                {/* Page content */}
                <section className="py-5">
                  <h3 className="page-title-green text-uppercase">Presences</h3>

                  <div className="row dashboard-row">
                    <div class="col-md-6">
                      <div class="d-flex flex-wrap">
                        <DateRangePicker
                          locale={fr}
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                          isOutsideRange={() => null}
                          moveRangeOnFirstSelection={false}
                          retainEndDateOnFirstSelection={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      <div className="card">
                        <div className="card-header ">
                          <h3 className="h6 text-uppercase mb-0">
                            Graphe des Pointages du{" "}
                            <span class="date">
                              {moment(startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </span>{" "}
                            au{" "}
                            <span class="date">
                              {moment(endDate).format("DD-MM-YYYY")}{" "}
                            </span>
                          </h3>
                        </div>

                        <div className="card-body">
                          <div className="col-md-12 delay-table-col">
                            {" "}
                            {/* <WeeklyPointageChart
                              data={cwlpdata}
                              labels={cwlpday}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h3 className="page-title-green text-uppercase">
                      Absences
                    </h3> */}

                    {/* <GrapheAbsence /> */}
                  </div>
                  <div className="col-lg-12 mb-5">
                    {/* <h3 className="page-title-green text-uppercase">
                      Presences/Absences
                    </h3> */}

                    {/* <PresentAbsentGraphe /> */}
                  </div>
                </section>
              </div>
              {/* Footer render */}
              {/* <Footer /> */}
            </div>
            {/* <div className="ui active inverted dimmer">
              <div className="ui text loader">Chargement en cours</div>
            </div> */}
          </div>
      </AppStructure>
    );
}
export default withHooksHOC(StatistiqueView);

// export default connect(
//     ({ statistiqueView }) => ({ ...statistiqueView }),
//     dispatch => bindActionCreators({ ...statistiqueViewActions }, dispatch)
//   )( statistiqueView );
