import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../appvars/globalsvariables";

export const getPointageByDateThunk = createAsyncThunk(
  "pointme/getPointageByDate",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API + `get-pointage-by-date/${arg.selectedDate}`
      );

      return {
        bakelistes: data.bakelistes,
        admins: data.admins,
        coachs: data.coachs,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getPointageFromDateToThunk = createAsyncThunk(
  "pointme/getPointageFromDateTo",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API + `bakeliste-pointage-from-date-to/${arg.from}/${arg.to}`
        );
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAbsenceFromDateToThunk = createAsyncThunk(
  "pointme/getAbsenceFromDateTo",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API + `bakeliste-absence-from-date-to/${arg.from}/${arg.to}`
        );

      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNombreAVenirThunk = createAsyncThunk(
  "pointme/getNombreAVenir",
  async (arg, { rejectWithValue }) => {
    try {
      const { data: nombreAVenir } = await axios.get(
        API + "pointme-infos/nombre_a_venir"
      );

      return nombreAVenir;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBakelisteHistorique = createAsyncThunk(
  "pointme/historique",
  async (bakelisteId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API + `bakeliste-absence/${bakelisteId}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
