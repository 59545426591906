import React from "react";
import ReactDOM from "react-dom/client";
import App from './App'
import "./index.css";
import { Provider } from 'react-redux'
import {persistor, store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import 'react-calendar/dist/Calendar.css';
// import * as serviceWorker from "./serviceWorker";
// import { BrowserRouter as Router, Route } from "react-router-dom";

// import Connexion from "./components/connexion/connexion";
// import Dashboard from "./components/dashboard/dashboard";
// import GestionAbsenceRetard from "./components/gestionAbsenceRetard/gestionAbsenceRetard";
// import AbsenceCounter from "./components/absenceCounter/absenceCounter";
// import InstallPwa from "./installPwa";

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  {/* <React.StrictMode> */}
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  {/* </React.StrictMode> */}
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
