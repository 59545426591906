import React, { Component } from "react";
import "./weeklyPointageChart.css";
import {Chart} from "chart.js";
let myLineChart;

//--Chart Style Options--//
// Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
// Chart.defaults.global.legend.display = false;
// Chart.defaults.global.elements.line.tension = 0;
//--Chart Style Options--//
// import classes from "./LineGraph.module.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as weeklyPointageChartActions from "../../store/weeklyPointageChart/actions";
export default class weeklyPointageChart extends Component {
  chartRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { data, average, labels } = this.props;

    if (typeof myLineChart !== "undefined") {
      myLineChart.destroy();
    }
    myLineChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Pointage par Jour",
            data: data,
            fill: true,
            borderColor: "red",
            backgroundColor: "#65a59f",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        events: false,
        tooltips: {
          enabled: false,
        },
        hover: {
          animationDuration: 0,
        },
        animation: {
          duration: 1,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              // Chart.defaults.global.defaultFontSize,
              // Chart.defaults.global.defaultFontStyle,
              // Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          },
        },
      },
    });
  };
  render() {
    return (
      <div className="graphContainer">
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
// export default connect(
//     ({ weeklyPointageChart }) => ({ ...weeklyPointageChart }),
//     dispatch => bindActionCreators({ ...weeklyPointageChartActions }, dispatch)
//   )( weeklyPointageChart );
