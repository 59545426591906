import React, { Component } from 'react';
import './header.css'
import API from './../../appvars/globalsvariables';
import { NavLink } from 'react-router-dom';
import { store } from '../../redux/store';
import { logoutAsync } from '../../redux/slices/userSlice';
import { useSelector } from 'react-redux';
//import axios from 'axios';
// import { Redirect } from 'react-router-dom'

const dispatch = store.dispatch
const Header =()=>{

  const firstName = useSelector(state=> state.users.currentUser.first_name)
  const lastName = useSelector(state=> state.users.currentUser.last_name)
  const userFullName = firstName +" " + lastName // a changer 
  const user_status = useSelector(state=> state.users.currentUser.status)


  const doLogoutUser = () => {
    // return <Redirect to='/' />
    dispatch(logoutAsync())
    window.location = '/'
  }

    return (
      <div className="component-header">
        <header className="header">
          <nav className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
            <NavLink to="#" className="sidebar-toggler text-gray-500 mr-4 mr-lg-5 lead">
              <i className="fas fa-align-left"></i>
            </NavLink>
            <NavLink to="dashboard" className="navbar-brand font-weight-bold text-uppercase text-base">
              <div className="">
                <span className="point-label">POINT</span><span className="me-label">me</span>
              </div>
            </NavLink>
            <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
              {/* <li className="nav-item dropdown mr-3">
                <a id="notifications" href="http://example.com" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle text-gray-400 px-1">
                  <i className="fa fa-bell"></i>
                  <span className="notification-icon"></span>
                </a>
                <div aria-labelledby="notifications" className="dropdown-menu"><a href="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-violet text-white"><i className="fab fa-twitter"></i></div>
                    <div className="text ml-2">
                      <p className="mb-0">You have 2 followers</p>
                    </div>
                  </div></a><a href="#" className="dropdown-item">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-sm bg-green text-white"><i className="fas fa-envelope"></i></div>
                      <div className="text ml-2">
                        <p className="mb-0">You have 6 new messages</p>
                      </div>
                    </div></a><a href="#" className="dropdown-item">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-sm bg-blue text-white"><i className="fas fa-upload"></i></div>
                      <div className="text ml-2">
                        <p className="mb-0">Server rebooted</p>
                      </div>
                    </div></a><a href="#" className="dropdown-item">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-sm bg-violet text-white"><i className="fab fa-twitter"></i></div>
                      <div className="text ml-2">
                        <p className="mb-0">You have 2 followers</p>
                      </div>
                    </div></a>
                  <div className="dropdown-divider"></div><a href="#" className="dropdown-item text-center"><small className="font-weight-bold headings-font-family text-uppercase">View all notifications</small></a>
                </div>
              </li> */}
              <li className="nav-item dropdown ml-auto">
                <NavLink id="userInfo" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle text-right">
                  <img src={require('../../assets/images/baks.png')} alt="Jason Doe" className="img-fluid rounded-circle shadow testy" />
                </NavLink>
                <div aria-labelledby="userInfo" className="dropdown-menu"><NavLink to="#" className="dropdown-item"><strong className="d-block text-uppercase headings-font-family"> {userFullName} </strong><small> {user_status} </small></NavLink>
                  {/* <div className="dropdown-divider"></div><a href="#" className="dropdown-item">Settings</a><a href="#" className="dropdown-item">Activity log       </a> */}
                  <div className="dropdown-divider"></div>
                  <span className="logout-item dropdown-item" onClick={doLogoutUser}> Logout </span>
                </div>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    );
}
export default Header;