import todayName from "./todayName";
import dayjs from "dayjs";
import "dayjs/locale/fr";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const MonthDayYearFormat = (dates) => {
  const days = []
  const goodDays = []
  dates.forEach((date) => {
    const day = todayName(date);
    if (day !== "samedi") {
      if (day !== "dimanche") {
        days.push(day);
        goodDays.push(dayjs(date).format("MM-DD-YYYY"));
      }
    }
  });

  return {days, goodDays}
};

export default MonthDayYearFormat;
