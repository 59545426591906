import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import getDatesBetweenTwoDates from "./getDatesBetweenTwoDates";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import {
  getAbsenceFromDateToThunk,
  getPointageFromDateToThunk
} from "../../../redux/services/getPointageByDateThunk";
import formatDate from "../formatDate";
import getWeekDays from "../../../utils/getWeekDates";
import getDaysWithoutWeekendDays from "../../../utils/getDatesWithoutWeekendDays";
import dataPresence from "./dataPresence";
import MonthDayYearFormat from "../../../utils/MonthDayYearFormat";
import todayName from "../../../utils/todayName";
import getFullDatesWithoutWeekendDays from "../../../utils/getFullDatesWithoutWeekendDays";
import getDaysWithNumberPointage from "../../../utils/getDaysWithNumberPointage";
import getDaysWithoutWeekendDaysWithObject from "../../../utils/getDaysWithoutWeekendDaysWithObject";
import { Tooltip } from "rsuite";
import ChartType from "./ChartType";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const PresenceChart = ({ nombreAVenir }) => {
  const [chartType, setCharType] = useState("bar");
  const [weekInterval, setWeekInterval] = useState({});
  const [range, setRange] = useState([]);
  const [yAxisPresence, setYPresenceYAxis] = useState([]);
  const [yAxisAbsence, setYAbsenceYAxis] = useState([]);
  const pointageFromDateTo = useSelector(
    (state) => state.pointme.pointageFromDateTo
  );
  const absenceFromDateTo = useSelector(
    (state) => state.absence.absenceFromDateTo
  );
  const pointageFromDateToLoading = useSelector(
    (state) => state.pointme.pointageFromDateToLoading
  );
  const absenceFromDateToLoading = useSelector(
    (state) => state.absence.absenceFromDateToLoading
  );
  const [datesForRequest, setDatesForRequest] = useState([]);
  const [categoriesXaxis, setCategoriesXaxis] = useState([]);
  const dispatch = useDispatch();
  const [jours, setJours] = useState([
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi"
  ]);

  const { series, options, locale } = dataPresence(
    nombreAVenir,
    jours,
    range,
    categoriesXaxis,
    yAxisPresence,
    yAxisAbsence
  );

  const handleDate = (value) => {
    if (value?.length) {
      setDatesForRequest(value);
      const dates = getDatesBetweenTwoDates(value[0], value[1]);
      const { days: jours, goodDays: goodDates } =
        getDaysWithoutWeekendDays(dates);
      dispatch(
        getPointageFromDateToThunk({
          from: formatDate(value[0]),
          to: formatDate(value[1])
        })
      );
      // ! absences
      dispatch(
        getAbsenceFromDateToThunk({
          from: formatDate(value[0]),
          to: formatDate(value[1])
        })
      );
      const weekDates = getWeekDays(new Date(value[1]));
      const { goodDays } = getDaysWithoutWeekendDays(weekDates);

      setWeekInterval({ from: goodDays[0], to: goodDays.at(-1) });
      setJours(jours);
      setRange(goodDates);
    }
  };

  const getDatesValues = (date1, date2) => {
    if (date1 && date2) {
      const fullPointagesDates = getFullDatesWithoutWeekendDays(pointageFromDateTo);
      const fullAbsencesDates = getFullDatesWithoutWeekendDays(absenceFromDateTo)

      const yAxisPresence = [];
      const yAxisAbsence = [];
      const shortenSchoolDate = getDaysWithNumberPointage(fullPointagesDates);
      const shortenAbsenceDate = getDaysWithNumberPointage(fullAbsencesDates);
      // console.log('fullAbscenceDate: ', shortenAbsenceDate);

      if (range.length) {
        shortenSchoolDate.forEach((item) => {
          range.forEach((rang) => {
            if (item.date === rang) {
              yAxisPresence.push(item.nombre);
            }
          });
        });
        // !absences
        shortenAbsenceDate.forEach((item) => {
          range.forEach((rang) => {
            if (item.date === rang) {
              yAxisAbsence.push(item.nombre);
            }
          });
        });
      } else {
        const schoolDays = getDaysWithoutWeekendDaysWithObject(fullPointagesDates);
        shortenSchoolDate.forEach((item) => {
          schoolDays.forEach((rang) => {
            if (item.date === rang) {
              yAxisPresence.push(item.nombre);
            }
          });
        });
        // !absences
        shortenAbsenceDate.forEach((item) => {
          schoolDays.forEach((rang) => {
            if (item.date === rang) {
              yAxisAbsence.push(item.nombre);
            }
          });
        });
      }
      setYPresenceYAxis(yAxisPresence);
      setYAbsenceYAxis(yAxisAbsence)
    }
  };

  useEffect(() => {
    if (datesForRequest.length) {
      // TODO: Third retard request
      getDatesValues(datesForRequest[0], datesForRequest[1]);
    } else {
      // TODO: Second retard request
      // console.log(absenceFromDateTo);
      const weekDates = getWeekDays(new Date());
      getDatesValues(weekDates[0], weekDates.at(-1));
    }
  }, [pointageFromDateToLoading, absenceFromDateToLoading]);

  useEffect(() => {
    // ! retourne les jours de la semaine en fonction de la date d'aujourd'hui
    const weekDates = getWeekDays(new Date());
    const { goodDays } = getDaysWithoutWeekendDays(weekDates);
    if (!weekInterval.from) {
      setWeekInterval({ from: goodDays[0], to: goodDays.at(-1) });
    }
    setCategoriesXaxis(goodDays);
    if (!datesForRequest.length) {
      // TODO: First Retard request goes here
      // ! abscences
      dispatch(
        getAbsenceFromDateToThunk({
          from: formatDate(weekDates[0]),
          to: formatDate(weekDates.at(-1))
        })
      );
        // !presences
      dispatch(
        getPointageFromDateToThunk({
          from: formatDate(weekDates[0]),
          to: formatDate(weekDates.at(-1))
        })
      );
    }
  }, [datesForRequest[0], datesForRequest[1]]);

  return (
    <div className="mt-5 p-2 border w-100">
      <div className="d-flex justify-content-between">
        <h6 className="my-2 fw-2 mr-2">
          Stats des bakélistes du{" "}
          <span style={{ color: "#28a745" }}>
            {`${
              weekInterval.from
                ? weekInterval?.from + " au " + weekInterval?.to
                : ""
            }`}
          </span>
        </h6>
        {/* <ChartType chartType={chartType} setCharType={setCharType} /> */}
      </div>
      <div style={{ width: "40%" }}>
        {/* <SelectFrequence /> */}
        <DateRangePicker
          // oneTap
          showOneCalendar
          locale={locale}
          hoverRange="week"
          ranges={[]}
          onChange={(value) => handleDate(value)}
          placeholder="Chosir date"
          // onSelect={(value) => handleSelect(value)}
        />
      </div>
      <Chart
        options={options}
        series={series}
        type={chartType ? chartType : "bar"}
      />
    </div>
  );
};

export default PresenceChart;
