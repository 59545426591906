import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { setShowBakelisteDetailsToTrue, setUserDetails } from "../../redux/slices/userSlice";

const CustomTable = ({
  data,
  status,
  setShowBakelisteDetails,
  dayName,
  todayDate
}) => {
  const nombreAVenir = useSelector((state) => state.pointme.nombreAVenir);
  const loading = useSelector((state) => state.pointme.loading);
  const nombreAVenirloading = useSelector(
    (state) => state.pointme.nombreAVenirloading
  );
  const dispatch = useDispatch()

  const options = {
    nextPage: "suivant",
    exportCSVText: "Export CSV",
    noDataText: loading ? (
      <div style={{ width: "10px !important" }} className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      "Il n'y a pas de données à afficher"
    )
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        className="btn btn-success"
        onClick={() => {
          dispatch(setShowBakelisteDetailsToTrue());
          dispatch(setUserDetails(row));
        }}
      >
        Details
      </button>
    );
  };

  function showDomainName(cell, row) {
    return cell?.name;
  }

  function isPresentiel(cell) {
    return cell ? "Oui" : "Non";
  }
  function isOnline(cell) {
    return cell ? "Oui" : "Non";
  }

  function priceFormatter(cell, row) {
    return '<i className""></i> ' + cell;
  }

  return (
    <div className="card mt-5">
      <div className="card-header bk_orange_bg">
        <h3 className="h6 text-uppercase mb-0">
          Liste de présence des {status} du{" "}
          <span style={{ color: "#009743" }}>{todayDate}</span>
        </h3>
      </div>
      <div className="p-2">
        {status === "bakelistes" && (
          <div
            style={{ fontSize: "1rem", color: "#009688" }}
            className="my-3 d-flex align-items-center justify-content-between"
          >
            <div style={{ margin: "0" }}>
              Nombre de {status} présent:{" "}
              {!nombreAVenirloading ? (
                nombreAVenir[dayName]?.length > 0 ? (
                  `${data.length} / ${nombreAVenir[dayName].length}`
                ) : (
                  "0/0"
                )
              ) : (
                <div
                  style={{ width: "17px", height: "17px" }}
                  className="spinner-grow"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
            {/* <PresenceByDomaine /> */}
          </div>
        )}
        <BootstrapTable
          exportCSV
          noDataIndication={() => "loading..."}
          maxWidth="1000"
          bordered
          options={options}
          pagination
          data={data}
          striped={true}
          hover={true}
        >
          <TableHeaderColumn
            dataField="id"
            isKey={true}
            dataAlign="center"
            dataSort={true}
            hidden
          >
            UserId
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="user_full_name"
            dataSort={true}
            dataAlign="center"
            filter={
              status === "bakelistes" && { type: "TextFilter", delay: 100 }
            }
          >
            Prénom et Nom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="pointage"
            dataSort={true}
            dataAlign="center"
          >
            Heure d'arrivée
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="domaine"
            dataSort={true}
            dataAlign="center"
            dataFormat={showDomainName}
            // filter={{ type: 'TextFilter', delay: 100 }}
          >
            Domaine
          </TableHeaderColumn>

          {status === "bakelistes" && (
            <TableHeaderColumn
              dataField="is_presentiel"
              dataSort={true}
              dataAlign="center"
              dataFormat={isPresentiel}
              // filter={{ type: 'TextFilter', delay: 100 }}
            >
              Présentiel
            </TableHeaderColumn>
          )}
          {status === "bakelistes" && (
            <TableHeaderColumn
              dataField="is_online"
              dataSort={true}
              dataAlign="center"
              dataFormat={isOnline}
              // filter={{ type: 'TextFilter', delay: 100 }}
            >
              En ligne
            </TableHeaderColumn>
          )}
          {status === "bakelistes" && (
            <TableHeaderColumn
              dataField="Actions"
              text="Actions"
              dataFormat={linkFollow}
              dataSort={true}
              dataAlign="center"
            >
              Actions
            </TableHeaderColumn>
          )}
        </BootstrapTable>
      </div>
    </div>
  );
};

export default CustomTable;
