import React, { useEffect } from "react";
import PresenceDetailsItem from "./PresenceDetailsItem";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { getBakelisteHistorique } from "../../redux/services/getPointageByDateThunk";
import { setShowBakelisteDetailsToFalse } from "../../redux/slices/userSlice";
import HistoriqueSelect from "../historiqueBakeliste/HistoriqueSelect";
import { getHistoriqueAbsence, getHistoriquePointage } from "../../redux/services/getHistoriquePointage";
import HistoriqueStats from "../historiqueBakeliste/HistoriqueStats";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import HistoriqueTable from "../historiqueBakeliste/HistoriqueTable";
import { deleteDateIntervel, presenceType } from "../../redux/slices/historiqueSlice";

var relativeTime = require("dayjs/plugin/relativeTime");

const PresenceDetails = () => {
  const dispatch = useDispatch();
  const details = useSelector((state) => state.users.userDetails);

  const timeFromX = (dateX, dateY) => {
    if (dateX > dateY) {
      throw new Error("La date de début doit etre inférieure à la date de fin");
    }

    dayjs.locale("fr");

    dayjs.extend(relativeTime);

    let a = dayjs(dateX);

    return dayjs(dateY).from(a, true);
  };

  useEffect(() => {
    dispatch(deleteDateIntervel())
    dispatch(getHistoriquePointage({ userId: details.id }));
    dispatch(getHistoriqueAbsence({ userId: details.id }));
  }, []);

  return (
    <>
      <button
        onClick={() => {
          dispatch(setShowBakelisteDetailsToFalse())
          dispatch(presenceType())
        }}
        className="btn btn-outline-secondary mb-2"
      >
        Retour
      </button>
      <div
        style={{
          backgroundColor: "white",
          minHeight: "90vh",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          paddingTop: "1rem"
        }}
        className="row presence-details"
      >
        {/* histoerique bakeliste */}
        <div className="col-lg-7 col-12">
          <h5 className="bakeliste__details--title">Historique de pointages</h5>
          <div>
            <HistoriqueSelect />
          </div>
          {/* <HistoriqueStats /> */}
          <HistoriqueTable userId={details.id} />
        </div>

        <div className="col-lg-5 col-12">
          <div className=" d-flex justify-content-between w-100">
            <h5 className="bakeliste__details--title">Plus d'informations</h5>
          </div>
          <PresenceDetailsItem
            label1="Prénom"
            text1={details?.first_name}
            label2="Horaire"
            text2={details?.horaire?.horaire ?? "Pas d'horaire"}
            isImportant1={false}
            isImportant2={true}
          />
          <PresenceDetailsItem
            label1="Nom"
            text1={details?.last_name}
            label2="Heure d'arrivée"
            text2={details?.pointage}
            isImportant1={false}
            isImportant2={true}
          />
          <PresenceDetailsItem
            label1="Niveau Bakeliste"
            text1={details?.bakeliste_level}
            label2="Jour(s) de formation"
            text2={`${
              details?.horaire?.jour_1 ? details.horaire?.jour_1 + "" : ""
            } ${details?.horaire?.jour_2 ? details.horaire?.jour_2 + "" : ""} ${
              details?.horaire?.jour_3 ? details.horaire?.jour_3 + "" : ""
            } ${details?.horaire?.jour_4 ? details.horaire?.jour_4 + "" : ""} ${
              details?.horaire?.jour_5 ? details.horaire?.jour_5 + "" : ""
            }`}
            isImportant1={false}
            isImportant2={true}
          />
          <PresenceDetailsItem
            label1="Type de formation"
            text1="courte"
            label2="Durée formation"
            text2={
              details?.debut_formation && details?.fin_formation
                ? timeFromX(details?.debut_formation, details?.fin_formation)
                : "Pas de durée de formation"
            }
            isImportant1={true}
            isImportant2={true}
          />
          <PresenceDetailsItem
            label1="Statut"
            text1={details?.status}
            label2="Domaine"
            text2={details?.domaine?.name ?? "Pas de domaine"}
            isImportant1={true}
            isImportant2={true}
          />
        </div>
      </div>
    </>
  );
};

export default PresenceDetails;
