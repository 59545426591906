import React from 'react'
import AppStructure from '../../AppStructure'
import PresenceView from '../PresenceView'
import '../presences.css'

const AdminPresenceView = () => {

    return (
        <AppStructure>
            <PresenceView status="admins" role="admins" />
        </AppStructure>
    )
}

export default AdminPresenceView