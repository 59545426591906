import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { history } from "../../history";
import { API } from "../../appvars/globalsvariables";
import { allPointMeAsync } from "./pointmeSlice";
import storage from "redux-persist/lib/storage";
import { getBakeliteAbsencePerMonthAsync } from "./absenceSlice";
import {
  getNombreAVenirThunk,
  getPointageByDateThunk
} from "../services/getPointageByDateThunk";
import formatDate from "../../components/presences/formatDate";
import localforage from "localforage";

const initialState = {
  currentUser: {},
  showBakelisteDetails: false,
  userDetails: {}
};

export const usersSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setShowBakelisteDetailsToTrue: (state) => {
      state.showBakelisteDetails = true
    },
    setShowBakelisteDetailsToFalse: (state) => {
      state.showBakelisteDetails = false
    },
    logout: (state) => {
      // storage.removeItem("persist:root");
      localforage.clear()
      state = initialState;
    },
    setUserDetails: (state, {payload}) => {
      state.userDetails = payload
    }
  }
});

export const setCurrentUserAsync =
  (data, setError, getAllPointage) => async (dispatch) => {
    try {
      const res = await axios.post(API + "user/login", data);

      if (res.data.data.can_access_pointme) {
        dispatch(setCurrentUser(res.data.data));
        dispatch(allPointMeAsync());
        dispatch(getBakeliteAbsencePerMonthAsync());
        dispatch(getNombreAVenirThunk());
        dispatch(
          getPointageByDateThunk({ selectedDate: formatDate(new Date()) })
        );
        return history.push("/dashboard");
      } else {
        return setError();
      }
    } catch (error) {
      setError();
    }
  };

export const logoutAsync = () => async (dispatch) => {
  dispatch(logout());
};

export const { setCurrentUser, setShowBakelisteDetailsToTrue, setShowBakelisteDetailsToFalse, setUserDetails, logout } = usersSlice.actions;
export default usersSlice.reducer;
