import React from 'react'
import AppStructure from '../../AppStructure'
import PresenceView from '../PresenceView'
import '../presences.css'

const CoachPresenceView = () => {

    return (
        <AppStructure>
            <PresenceView status="coachs" role="coachs" />
        </AppStructure>
    )
}

export default CoachPresenceView