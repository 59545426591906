import React, { Component } from "react";
import "./statistique.css";
import { Database, Users, User, UserCheck } from "react-feather";
import axios from "axios";
import API from "./../../appvars/globalsvariables";
import { Link } from "react-router-dom";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as statistiqueActions from "../../store/statistique/actions";
export default class statistique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      countPointagesDaily: [],
      countpointagesDailyBakeliste: [],
      countpointagesDailyCoach: [],
      countpointagesDailyAdmin: [],
      countpointagesYesterday: [],
      countpointagesYesterdayBakeliste: [],
      countpointagesYesterdayCoach: [],
      countpointagesYesterdayAdmin: [],
      countpointagesWeekly: [],
      countpointagesWeeklyBakeliste: [],
      countpointagesWeeklyCoach: [],
      countpointagesWeeklyAdmin: [],
      countpointagesMonthly: [],
      countpointagesMonthlyBakeliste: [],
      countpointagesMonthlyCoach: [],
      countpointagesMonthlyAdmin: [],
      count_liste_absence_bakeliste_daily: [],
      isPageLoading: false,
    };
  }

  componentDidMount = () => {
    this.getCOuntPointages();
  };

  getCOuntPointages = () => {
    let apiBaseUrl = this.state.api;
    this.setState({
      isPageLoading: true,
    });

    axios.get(apiBaseUrl + "pointme-all-infos").then((response) => {
      this.setState({
        isPageLoading: false,
        countPointagesDaily: response.data.countpointagesDaily,
        countpointagesDailyBakeliste:
          response.data.countpointagesDailyBakeliste,
        countpointagesDailyCoach: response.data.countpointagesDailyCoach,
        countpointagesDailyAdmin: response.data.countpointagesDailyAdmin,
        countpointagesYesterday: response.data.countpointagesYesterday,
        countpointagesYesterdayBakeliste:
          response.data.countpointagesYesterdayBakeliste,
        countpointagesYesterdayCoach:
          response.data.countpointagesYesterdayCoach,
        countpointagesYesterdayAdmin:
          response.data.countpointagesYesterdayAdmin,
        countpointagesWeekly: response.data.countpointagesWeekly,
        countpointagesWeeklyBakeliste:
          response.data.countpointagesWeeklyBakeliste,
        countpointagesWeeklyCoach: response.data.countpointagesWeeklyCoach,
        countpointagesWeeklyAdmin: response.data.countpointagesWeeklyAdmin,
        countpointagesMonthly: response.data.countpointagesMonthly,
        countpointagesMonthlyBakeliste:
          response.data.countpointagesMonthlyBakeliste,
        countpointagesMonthlyCoach: response.data.countpointagesMonthlyCoach,
        countpointagesMonthlyAdmin: response.data.countpointagesMonthlyAdmin,
        count_liste_absence_bakeliste_daily:
          response.data.count_liste_absence_bakeliste_daily,
      });
    });
  };

  render() {
    if (this.state.isPageLoading) {
      return (
        <div className="component-gestion-absence-retard">
          {/* Header render */}

          <div className="page-holder w-100 d-flex flex-wrap">
            <div className="container-fluid px-xl-5">
              {/* Page content */}
              <section className="py-5">
                <div className="row dashboard-row">
                  <div className="col-lg-6 mb-5">
                    <div className="card">
                      <h3 className="page-title-green">
                        Pointages d'Aujourd'hui
                      </h3>

                      <div className="card-body">In progress</div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="ui active inverted dimmer">
              <div className="ui text loader">Chargement en cours</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="component-statistique mt-5">
        {/* POintages du jour */}
        <h3 className="page-title-green">Pointages d'Aujourd'hui</h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Database color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Pointages</span>
              <span class="value">
                <span>{this.state.countPointagesDaily}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <Link to="/statistiques-bakeliste">
              <div class="rad-info-box rad-txt-primary">
                <i>
                  <Users color="orange" size={48} />{" "}
                </i>
                <span class="heading">Bakelistes</span>
                <span class="value">
                  <span>{this.state.countpointagesDailyBakeliste}</span>
                </span>
              </div>
            </Link>
          </div>

          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <User color="red" size={48} />
              </i>{" "}
              <span class="heading">Coach</span>
              <span class="value">
                <span>{this.state.countpointagesDailyCoach}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <UserCheck color="green" size={48} />{" "}
              </i>
              <span class="heading">Admin</span>
              <span class="value">
                <span>{this.state.countpointagesDailyAdmin}</span>
              </span>
            </div>
          </div>
        </div>

        {/* Pointages d'hier */}
        <h3 className="page-title">Pointages d'hier</h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Database color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Pointages</span>
              <span class="value">
                <span>{this.state.countpointagesYesterday}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-primary">
              <i>
                <Users color="orange" size={48} />{" "}
              </i>
              <span class="heading">Bakelistes</span>
              <span class="value">
                <span>{this.state.countpointagesYesterdayBakeliste}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <User color="red" size={48} />
              </i>{" "}
              <span class="heading">Coach</span>
              <span class="value">
                <span>{this.state.countpointagesYesterdayCoach}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <UserCheck color="green" size={48} />{" "}
              </i>
              <span class="heading">Admin</span>
              <span class="value">
                <span>{this.state.countpointagesYesterdayAdmin}</span>
              </span>
            </div>
          </div>
        </div>

        {/* Pointages de la semaine */}
        <h3 className="page-title">Pointages de la semaine</h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Database color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Pointages</span>
              <span class="value">
                <span>{this.state.countpointagesWeekly}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-primary">
              <i>
                <Users color="orange" size={48} />{" "}
              </i>
              <span class="heading">Bakelistes</span>
              <span class="value">
                <span>{this.state.countpointagesWeeklyBakeliste}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <User color="red" size={48} />
              </i>{" "}
              <span class="heading">Coach</span>
              <span class="value">
                <span>{this.state.countpointagesWeeklyCoach}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <UserCheck color="green" size={48} />{" "}
              </i>
              <span class="heading">Admin</span>
              <span class="value">
                <span>{this.state.countpointagesWeeklyAdmin}</span>
              </span>
            </div>
          </div>
        </div>

        {/* Pointages du mois */}
        <h3 className="page-title-green">Pointages du mois</h3>
        <div class="row mt-3">
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-success">
              <i>
                <Database color="lightgreen" size={48} />{" "}
              </i>
              <span class="heading">Pointages</span>
              <span class="value">
                <span>{this.state.countpointagesMonthly}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-primary">
              <i>
                <Users color="orange" size={48} />{" "}
              </i>
              <span class="heading">Bakelistes</span>
              <span class="value">
                <span>{this.state.countpointagesMonthlyBakeliste}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box rad-txt-danger">
              <i>
                <User color="red" size={48} />
              </i>{" "}
              <span class="heading">Coach</span>
              <span class="value">
                <span>{this.state.countpointagesMonthlyCoach}</span>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-xs-6">
            <div class="rad-info-box">
              <i>
                <UserCheck color="green" size={48} />{" "}
              </i>
              <span class="heading">Admin</span>
              <span class="value">
                <span>{this.state.countpointagesMonthlyAdmin}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ statistique }) => ({ ...statistique }),
//     dispatch => bindActionCreators({ ...statistiqueActions }, dispatch)
//   )( statistique );
