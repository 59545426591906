import React, {  useState,useEffect } from "react";
import "./statistique.css";
import { Database, Users, User, UserCheck } from "react-feather";
import axios from "axios";
import API from "./../../appvars/globalsvariables";
// import Statistique from "../statistique/statistique";
import { Link } from "react-router-dom";
import { StatistiqueCard } from "./StatistiqueCard";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as statistiqueActions from "../../store/statistique/actions";

const Statistique = ()=>{
    const [countPointagesDaily,setCountPointagesDaily] = useState([])
    const [countpointagesDailyBakeliste,setCountpointagesDailyBakeliste] = useState([])
    const [countpointagesDailyCoach,setCountpointagesDailyCoach] = useState([])
    const [countpointagesDailyAdmin,setCountpointagesDailyAdmin] = useState([])
    const [countpointagesYesterday,setCountpointagesYesterday] = useState([])
    const [countpointagesYesterdayBakeliste,setCountpointagesYesterdayBakeliste] = useState([])
    const [countpointagesYesterdayCoach,setCountpointagesYesterdayCoach] = useState([])
    const [countpointagesYesterdayAdmin,setCountpointagesYesterdayAdmin] = useState([])
    const [countpointagesWeekly,setCountpointagesWeekly] = useState([])
    const [countpointagesWeeklyBakeliste,setCountpointagesWeeklyBakeliste] = useState([])
    const [countpointagesWeeklyCoach,setCountpointagesWeeklyCoach] = useState([])
    const [countpointagesWeeklyAdmin,setCountpointagesWeeklyAdmin] = useState([])
    const [countpointagesMonthly,setCountpointagesMonthly] = useState([])
    const [countpointagesMonthlyBakeliste,setCountpointagesMonthlyBakeliste] = useState([])
    const [countpointagesMonthlyCoach,setCountpointagesMonthlyCoach] = useState([])
    const [countpointagesMonthlyAdmin,setCountpointagesMonthlyAdmin] = useState([])
    const [count_liste_absence_bakeliste_daily,setCount_liste_absence_bakeliste_daily] = useState([])
    const [isPageLoading,setisPageLoading] = useState(false)

    const api = API.API

    useEffect(()=>{
        getCountPointages()
    },[])

  const getCountPointages = () => {

    setisPageLoading(true)

    axios.get(api + "pointme-all-infos").then((response) => {
        console.log(response.data.countpointagesYesterday)
        setisPageLoading(false)
        setCountPointagesDaily(response.data.countpointagesDaily)
        setCountpointagesDailyBakeliste(response.data.countpointagesDailyBakeliste)
        setCountpointagesDailyCoach(response.data.countpointagesDailyCoach)
        setCountpointagesDailyAdmin(response.data.countpointagesDailyAdmin)
        setCountpointagesYesterday(response.data.countpointagesYesterday)
        setCountpointagesYesterdayBakeliste(response.data.countpointagesYesterdayBakeliste)
          
        setCountpointagesYesterdayCoach(response.data.countpointagesYesterdayCoach)
        setCountpointagesYesterdayAdmin(response.data.countpointagesYesterdayAdmin)
          
        setCountpointagesWeekly(response.data.countpointagesWeekly)
        setCountpointagesWeeklyBakeliste(response.data.countpointagesWeeklyBakeliste)
          
        setCountpointagesWeeklyCoach(response.data.countpointagesWeeklyCoach)
        setCountpointagesWeeklyAdmin(response.data.countpointagesWeeklyAdmin)
        setCountpointagesMonthly(response.data.countpointagesMonthly)
        setCountpointagesMonthlyBakeliste(response.data.countpointagesMonthlyBakeliste)
          
        setCountpointagesMonthlyCoach(response.data.countpointagesMonthlyCoach)
        setCountpointagesMonthlyAdmin(response.data.countpointagesMonthlyAdmin)
        setCount_liste_absence_bakeliste_daily(response.data.count_liste_absence_bakeliste_daily)
      });
  };


    return (
      <div className="component-statistique mt-5">
        {/* POintages du jour */}
        <h3 className="page-title-green">Pointages d'Aujourd'hui</h3>
        <div class="row mt-3">
          <StatistiqueCard
            title="Pointage"
            isLoading={isPageLoading}
            value={countPointagesDaily}
            icon={<Database color="lightgreen" size={48} />}
          />
          <StatistiqueCard
            title="Bakelistes"
            isLoading={isPageLoading}
            value={countpointagesDailyBakeliste}
            icon={<Users color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Coach"
            isLoading={isPageLoading}
            value={countpointagesDailyCoach}
            icon={<User color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Admin"
            isLoading={isPageLoading}
            value={countpointagesDailyAdmin}
            icon={<UserCheck color="orange" size={48} />}
          />
        </div>

        {/* Pointages d'hier */}
        <h3 className="page-title">Pointages d'hier</h3>
        <div class="row mt-3">
          <StatistiqueCard
            title="Pointage"
            isLoading={isPageLoading}
            value={countpointagesYesterday}
            icon={<Database color="lightgreen" size={48} />}
          />
          <StatistiqueCard
            title="Bakelistes"
            isLoading={isPageLoading}
            value={countpointagesYesterdayBakeliste}
            icon={<Users color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Coach"
            isLoading={isPageLoading}
            value={countpointagesYesterdayCoach}
            icon={<User color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Admin"
            isLoading={isPageLoading}
            value={countpointagesYesterdayAdmin}
            icon={<UserCheck color="orange" size={48} />}
          />
        </div>

        {/* Pointages de la semaine */}
        <h3 className="page-title">Pointages de la semaine</h3>
        <div class="row mt-3">
          <StatistiqueCard
            title="Pointage"
            isLoading={isPageLoading}
            value={countpointagesWeekly}
            icon={<Database color="lightgreen" size={48} />}
          />
          <StatistiqueCard
            title="Bakelistes"
            isLoading={isPageLoading}
            value={countpointagesWeeklyBakeliste}
            icon={<Users color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Coach"
            isLoading={isPageLoading}
            value={countpointagesWeeklyCoach}
            icon={<User color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Admin"
            isLoading={isPageLoading}
            value={countpointagesWeeklyAdmin}
            icon={<UserCheck color="orange" size={48} />}
          />
        </div>
        {/* Pointages du mois */}
        <h3 className="page-title-green">Pointages du mois</h3>
        <div class="row mt-3">
          <StatistiqueCard
            title="Pointage"
            isLoading={isPageLoading}
            value={countpointagesMonthly}
            icon={<Database color="lightgreen" size={48} />}
          />
          <StatistiqueCard
            title="Bakelistes"
            isLoading={isPageLoading}
            value={countpointagesMonthlyBakeliste}
            icon={<Users color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Coach"
            isLoading={isPageLoading}
            value={countpointagesMonthlyCoach}
            icon={<User color="orange" size={48} />}
          />

          <StatistiqueCard
            title="Admin"
            isLoading={isPageLoading}
            value={countpointagesMonthlyAdmin}
            icon={<UserCheck color="orange" size={48} />}
          />
        </div>
      </div>
    );
}

export default Statistique;