import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "./presences.css";
import CustomTable from "./CustomTable";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "./formatDate";
import PresenceDetails from "./PresenceDetails";
import todayName from "../../utils/todayName";
import {
  getNombreAVenirThunk,
  getPointageByDateThunk
} from "../../redux/services/getPointageByDateThunk";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import PresenceChart from "./presenceCharts/PresenceChart";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.locale("fr");

dayjs.extend(relativeTime);

const PresenceView = ({ status, role }) => {
  // const [showBakelisteDetails, setShowBakelisteDetails] = useState(false);
  const [details, setDetails] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [dayName, setDayName] = useState(todayName(date));
  const showBakelisteDetails = useSelector(state => state.users.showBakelisteDetails)
  const loading = useSelector((state) => state.pointme.loading);
  const bakelistes = useSelector((state) => state.pointme.bakelistes);
  const coachs = useSelector((state) => state.pointme.coachs);
  const admins = useSelector((state) => state.pointme.admins);
  const [todayDate, setTodayDate] = useState("");
  const nombreAVenir = useSelector((state) => state.pointme.nombreAVenir);

  useEffect(() => {
    const dayName = todayName(date);
    setDayName(dayName);
    setTodayDate(dayjs(date).format("dddd DD MMMM YYYY"));
    
    if(!isSelected && dayName){
      dispatch(getNombreAVenirThunk());
    }
    if (dayName) {
      dispatch(getPointageByDateThunk({ selectedDate: formatDate(date) }));
    }
  }, [date]);

  return (
    <div className="row">
      {!showBakelisteDetails ? (
        <>
          <div className="col-md-4 col-12">
            <h2 className="text-center">Choisissez le jour</h2>
            <Calendar
              tileDisabled={({ date, view }) =>
                (view === "month" && date.getDay() === 0) || date.getDay() === 6
              }
              onClickDay={(value, event) => {
                setIsSelected(true);
                setDate(value);
              }}
              className="calendar-container w-100"
            />     
            {
              status === 'bakelistes' && <PresenceChart nombreAVenir={nombreAVenir} />
            }     
          </div>
          <div className="col-md-8 col-12">
            <CustomTable
              todayDate={todayDate}
              dayName={dayName}
              status={status}
              data={
                !isSelected && !loading
                  ? role === "bakelistes"
                    ? bakelistes
                    : role === "admins"
                    ? admins
                    : role === "coachs"
                    ? coachs
                    : []
                  : !loading
                  ? role === "bakelistes"
                    ? bakelistes
                    : role === "admins"
                    ? admins
                    : role === "coachs"
                    ? coachs
                    : []
                  : []
              }
            />
          </div>
        </>
      ) : (
        <PresenceDetails
          status={status}
        />
      )}
    </div>
  );
};

export default PresenceView;
