import React from "react";
import PWAInstallerPrompt from "react-pwa-installer-prompt";
// import FeatherIcon from "feather-icons-react";
import { isMobile } from "react-device-detect";

const InstallPwa = () => {
  if (isMobile) {
    return (
      <PWAInstallerPrompt
        render={({ onClick }) => (
          <button onClick={onClick} className="add_to_screen_btn">
            {/* <FeatherIcon icon="home" className="home-add-screen-icon" /> */}
            &nbsp; Ajouter sur votre écran d'accueil&nbsp;
          </button>
        )}
        callback={(data) => console.log(data)}
      />
    );
  }

  return "";
};

export default InstallPwa;
