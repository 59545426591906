export const HOME_PATH = '/'

export const DASHBOARD_PATH = '/dashboard'

export const GESTION_ABSENCE_RETARD_PATH = '/gestion-absence-retard'

export const COUNT_ABSENCE_DASHBOARD_PATH = '/count-absence-dashboard'

export const STATISTIQUES_PATH = '/statistiques'

export const STATISTIQUES_BAKELISTES_PATH = '/statistiques-bakeliste'

export const BAKELISTES_PRESENCE_PATH = '/bakeliste-presence'

export const ADMIN_PRESENCE_PATH = '/admin-presence'

export const COACH_PRESENCE_PATH = '/coach-presence'